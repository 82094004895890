<template>
  <div>
    <Navbar />
    <main>
      <router-view></router-view>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/sections/footer/Footer";
import { computed, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  setup() {
    const store = useStore();

    const message = computed(() => {
      return store.getters.message;
    });

    watchEffect(() => {
      if (message.value != "not_pass") {
        message.value = store.state.message;

        // if (message.value == "internal_error") {
          // return store.dispatch("RESET_STATE");
        // }
      }
    });

    return {
      message
    };
  },
};
</script>

<style>
body {
  -webkit-print-color-adjust: exact !important;
}

@media (max-width: 768px) {
  .ev-logo {
    height: 50px;
    width: auto;
  }
  section .ev-bg-green,
  .ev-main-widget {
    border-radius: 0 !important ;
  }
}

#section-vehicle {
  background-image: url("./assets/img/bg-vehicle.svg");
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 800px;
}
#section-stolen {
  background-image: url("./assets/img/bg-stolen.svg");
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 800px;
}
#section-odometer {
  background-image: url("./assets/img/bg-odometer.svg");
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 800px;
}

.h-90 {
  height: 90% !important;
}

.table-odometer > :not(caption) > * > * {
  padding: 0.15rem 1rem;
}

.ev-bg-footer {
  background-color: #148245;
}

.ev-bg-green {
  background-color: #148245;
}

.ev-text-green {
  color: #148245;
}

.ev-bg-orange {
  background-color: #ea9b28;
}

.ev-text-orange {
  color: #ea9b28;
}

.ev-toc:checked,
.ev-toc:focus {
  background-color: #ea9b28 !important;
  border-color: #ea9b28 !important;
}

.ev-vin-input,
.ev-plate-input,
.ev-date-input,
.ev-check-button,
.ev-vin-input:focus,
.ev-plate-input:focus,
.ev-date-input:focus,
.ev-check-button:focus {
  border: 0;
  border-radius: 25px !important;
  outline: none !important;
}

.ev-plate-input-reg {
  font-weight: 700;
  border: 0;
  outline: none !important;
  background-color: transparent !important;
}

.ev-vin-input,
.ev-plate-input,
.ev-date-input {
  font-weight: 700;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%) !important;
}
.ev-plate-input:disabled,
.ev-date-input:disabled,
.ev-vin-input:disabled {
  opacity: 0.9;
  background-color: #a2c9b2 !important;
}

.ev-plate-input::placeholder,
.ev-date-input::placeholder,
.ev-vin-input::placeholder,
.ev-plate-input-reg::placeholder {
  color: #b1bdca;
}

.ev-bg-anim {
  background: linear-gradient(-45deg, #148245, #0ca551, #0a582d, #155c35);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ev-vin-top-button {
  background-color: #148245;
  border-radius: 0px 25px 25px 0px !important;
  color: #ffffff;
}
.ev-vin-top-button:hover {
  background-color: #0ca551;
  color: #ffffff;
}

.ev-vin-top-input {
  border-radius: 25px 0px 0px 25px !important;
  height: 45px !important;
}
.ev-vin-top-input:focus {
  outline: none !important;
}

#ev-widget-tabs li {
  margin: 0 auto;
}

#ev-widget-tabs .nav-link {
  border: 0;
  border-radius: 25px !important;
  background-color: #00913b;
  color: #afdabe;
  padding: 8px 14px;
}

#ev-widget-tabs .nav-link.active {
  border: 0;
  border-radius: 25px !important;
  background-color: #00b74a;
  color: #ffffff;
}

.pc-reg {
  background-color: #fff;
  padding: 0.1em 0.5em 0.1em 1.5em;
  font-weight: bold;
  font-size: 2em;
  border-radius: 25px;
  border: 0;
  position: relative;
  font-family: helvetica, ariel, sans-serif;
  max-width: 300px;
  margin: 0 auto;
}

.pc-reg:before {
  width: 40px;
  height: 100%;
  background: #0053cf;
  position: absolute;
  top: 0;
  border-radius: 25px;
  color: #ffffff;
  font-size: 14px;
  line-height: 70px;
  padding-left: 0px;
  left: 0;
  text-align: center;
  content: "PL";
}

.pc-reg:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 11px;
  width: 17px;
  height: 17px;
  border-radius: 30px;
  border: 2px dotted #f5ca2e;
}

/* report CSS */

.ev-label-item span {
  color: #888888;
  display: block;
}
.ev-label-item p {
  font-weight: 500;
  color: #4f4f4f;
}

/* timeline */
.ev-timeline {
  margin-top: -40px;
  padding-top: 30px;
}
.ev-label-head .ev-label-item span {
  color: #cccccc;
  text-shadow: 1px 1px 3px #054623;
}
.ev-label-head .ev-label-item p {
  color: #ffffff;
  text-shadow: 1px 1px 3px #054623;
}

/* PRINT CSS */

/* Print fixes */
@media print {
  .p-none {
    display: none;
  }

  .col-print-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-print-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-print-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-print-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-print-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-print-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-print-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  footer.bg-light {
    background-color: #ffffff !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .no-print {
    display: none !important;
  }

  .collapse {
    display: block !important;
    height: auto !important;
  }
  .container {
    width: auto;
  }

  img,
  svg,
  ul {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  footer {
    display: none;
  }

  table,
  figure {
    page-break-inside: avoid;
  }

  #section-header,
    /*#section-vehicle,
     #section-stolen, */
    #section-timeline,
    #section-odometer,
    #section-valuation {
    clear: both;
    page-break-before: always;
    break-inside: always;
    -webkit-print-color-adjust: exact;
  }

  .ev-tl-item {
    page-break-before: avoid;
    break-inside: avoid;
    display: block;
  }

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
}
</style>
