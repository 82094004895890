<template>
  <div class="col-md-4 col-lg-4 col-print-4 my-2">
    <div class="p-2 bg-success rounded-4 shadow-2-strong d-inline-block mb-4">
      <i class="fas fa-chevron-down fa-2x text-white fa-fw"></i>
    </div>
    <div
      class="pb-2 px-2 rounded-5 shadow-2-strong h-90"
      style="
        background: hsl(220, 33.3%, 98.2%);
        margin-top: -50px;
        padding-top: 50px;
      "
    >
      <h4 class="fw-bold text-success mb-2">{{ valuationData.price_min ?? '-' }} PLN</h4>
      <p class="mb-2 lead"><strong>Minimalna wartość rynkowa</strong></p>
      <p class="mb-0 small text-muted">
        Wartość pojazdu poniżej wartości rynkowej to pojazdy w gorszym stanie
        technicznym lub z dużym przebiegiem.
      </p>
    </div>
  </div>

  <div class="col-md-4 col-lg-4 col-print-4 my-2">
    <div class="p-2 bg-info rounded-4 shadow-2-strong d-inline-block mb-4">
      <i class="fas fa-money-bill-wave fa-2x text-white fa-fw"></i>
    </div>
    <div
      class="pb-2 px-2 rounded-5 h-90 shadow-2-strong border border-info"
      style="
        background: hsl(220, 33.3%, 98.2%);
        margin-top: -50px;
        padding-top: 50px;
      "
    >
      <h4 class="fw-bold text-info mb-2">{{ valuationData.price_avr ?? '-' }} PLN</h4>
      <p class="mb-2 lead"><strong>Średnia wartość rynkowa</strong></p>
      <p class="mb-0 small text-muted">
        Średnia wartość rynkowa odnosi się do pojazdów w dobrym stanie
        technicznym i umiarkowanym przebiegu.
      </p>
    </div>
  </div>

  <div class="col-md-4 col-lg-4 col-print-4 my-2">
    <div class="p-2 bg-warning rounded-4 shadow-2-strong d-inline-block mb-4">
      <i class="fas fa-chevron-up fa-2x text-white fa-fw"></i>
    </div>
    <div
      class="pb-2 px-2 rounded-5 h-90 shadow-2-strong"
      style="
        background: hsl(220, 33.3%, 98.2%);
        margin-top: -50px;
        padding-top: 50px;
      "
    >
      <h4 class="fw-bold text-warning mb-2">{{ valuationData.price_max ?? '-' }} PLN</h4>
      <p class="mb-2 lead"><strong>Maksymalna wartość rynkowa</strong></p>
      <p class="mb-0 small text-muted">
        Wartość pojazdu powyżej wartości rynkowej to pojazdy w bardzo dobrym
        stanie technicznym i z niskim przebiegiem.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Valuation",
  props: {
    valuationData: Object,
  }
};
</script>

<style>
</style>