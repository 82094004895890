<template>
  <div class="container mt-0 mt-md-2 p-0 p-md-3">
    <!-- Section: Design Block -->
    <section class="ev-main-widget ev-bg-anim rounded-6 shadow-6-strong">
      <div
        class="d-flex justify-content-center pt-3 px-3 rounded-6"
        style="background-color: #00000033"
      >
        <div class="text-center text-white" style="max-width: 700px">
          <h2 class="fw-bold mb-3">Uzupełnij dane pojazdu</h2>
          <p class="mb-3">
            Uzupełnij brakujące dane pojazdu, aby pobrać darmowy raport z
            historią pojazdu.
          </p>
        </div>
      </div>

      <div class="row gx-lg-5 p-4 p-md-5">
        <div class="col-lg-5 mb-4 mb-lg-0 align-self-center">
          <form @submit.prevent="checkForm">
            <!-- Name input -->
            <div class="mb-4">
              <div class="form-text text-white text-center">
                Numer VIN pojazdu
              </div>
              <input
                type="text"
                id="vin"
                class="
                  form-control form-control-lg
                  text-center text-uppercase
                  bg-white
                  ev-vin-input
                "
                v-model="vin"
              />
            </div>

            <!-- Name input -->
            <div class="mb-4">
              <div class="form-text text-white text-center">
                Numer rejestracyjny pojazdu
              </div>
              <div class="pc-reg shadow-4-strong">
                <input
                  type="text"
                  id="plate"
                  placeholder="Wpisz tablicę rej."
                  class="
                    form-control form-control-lg
                    text-center text-uppercase
                    bg-white
                    ev-plate-input-reg
                  "
                  maxlength="10"
                  v-model="plate"
                />
              </div>
            </div>

            <!-- Name input -->
            <div class="mb-4">
              <div class="form-text text-white text-center">
                Data pierwszej rejestracji
              </div>
              <!-- <input
                type="text"
                id="firstreg"
                placeholder="DD.MM.RRRR"
                class="
                  form-control form-control-lg
                  text-center text-uppercase
                  bg-white
                  ev-date-input
                "
                v-model="date"
              /> -->
              <MDBDatepicker
                class="
                  form-control form-control-lg
                  text-center text-uppercase
                  bg-white
                  ev-date-input
                "
                v-model="date"
                inline
                inputToggle
                :toggleButton="false"
                placeholder="DD.MM.RRRR"
                format="DD.MM.YYYY"
                :monthsFull="['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']"
                :monthsShort="['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru']"
                :weekdaysNarrow="['N', 'P', 'W', 'Ś', 'C', 'P', 'S']"
                :max="maxDate"
              />
            </div>

            <!-- Checkbox -->
            <div class="form-check d-flex justify-content-center mb-4">
              <input
                class="form-check-input ev-toc me-2"
                type="checkbox"
                v-model="rulesCheckbox"
                id="toc"
              />
              <label class="form-check-label text-white" for="toc">
                Zapoznalem się z Regulaminem serwisu
              </label>
            </div>

            <!-- Submit button -->
            <button
              type="submit"
              class="btn btn-success btn-block btn-lg mb-0 ev-check-button"
              :disabled="!disabledBtn"
            >
              <i class="fas fa-search me-3"></i> Sprawdź pojazd
            </button>
          </form>
        </div>

        <div class="col-lg-7 mt-4 mt-lg-0 mb-4 mb-md-0 align-self-center">
          <div class="card mb-3">
            <div class="row g-0">
              <div class="col-md-4 order-last order-md-first">
                <img
                  src="../assets/img/dow-rejestracyjny.svg"
                  alt="dowód rejestracyjny"
                  loading="lazy"
                  class="img-fluid m-0 m-sm-2 rounded-start"
                />
              </div>
              <div class="col-md-8 order-first order-md-last">
                <div class="card-body">
                  <h5 class="card-title mb-1">Gdzie szukać danych?</h5>
                  <p class="mb-3 small">
                    Brakujące dane znajdziesz w
                    <strong>dowodzie rejestracyjnym</strong> pojazdu lub możesz
                    poprosić sprzedawcę o ich przekazanie.
                  </p>

                  <h6 class="card-title mb-0">NUMER REJESTRACYJNY</h6>
                  <p class="card-text">
                    Pozycja <span class="badge bg-warning">A</span> w dowodzie
                    rejestracyjnym
                  </p>

                  <h6 class="card-title mb-0">NUMER VIN</h6>
                  <p class="card-text">
                    Pozycja <span class="badge bg-warning">E</span> w dowodzie
                    rejestracyjnym
                  </p>

                  <h6 class="card-title mb-0">DATA PIERWSZEJ REJESTRACJI</h6>
                  <p class="card-text">
                    Pozycja <span class="badge bg-warning">B</span> w dowodzie
                    rejestracyjnym
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section: Design Block -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// import sendReq from "@/composables/sendReq";
import moment from "moment";
// import checkResponse from "@/composables/checkResponse";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, watchEffect } from "@vue/runtime-core";
import { MDBDatepicker } from "mdb-vue-ui-kit";

export default {
  name: "FillData",
  components: { MDBDatepicker },
  setup() {
    const store = useStore();
    const vin = ref("");
    const plate = ref("");
    const date = ref("");
    const router = useRouter();
    const getVin = ref(router.currentRoute.value.params.vin || "");
    const getPlate = ref(router.currentRoute.value.params.plate || "");
    const getDate = ref(router.currentRoute.value.params.date || "");

    const rulesCheckbox = ref(false);

    const disabledBtn = computed(() => {
      return (
        vin.value.length == 17 &&
        plate.value.length > 4 &&
        date.value.length > 3 &&
        rulesCheckbox.value === true
      );
    });

    const checkedVin = computed(() => {
      return store.state.checkedVin;
    });

    const maxDate = computed(() => {
      return moment(new Date().setDate(new Date().getDate() + 1)).format('DD.MM.YYYY');
    });

    const dataToAutofill = computed(() => {
      return store.state.fillData;
    });

    watchEffect(() => {
      if (Object.keys(dataToAutofill.value).length != 0) {
        try {
          if (dataToAutofill.value.vin) {
            vin.value = dataToAutofill.value.vin;
          }

          if (dataToAutofill.value.plate) {
            plate.value = dataToAutofill.value.plate;
          }

          if (dataToAutofill.value.date) {
            date.value = dataToAutofill.value.date;
          }
        } catch (error) {
          console.error(error);
        }
      }
    });

    const checkForm = async () => {
      if (vin && vin.value.length === 17 && plate && plate.value.length > 5) {
        store.dispatch("setCheckedVin", vin.value);
        router.push({
          name: "loader",
          params: {
            vin: vin.value,
            plate: plate.value,
            date: moment(String(date.value), "DD.MM.YYYY").format("DD.MM.YYYY"),
          },
        });
      }
      // const send = await sendForm(vin.value, plate.value, true, token.value);

      // if (send) {
      //   const { directionRouter } = checkResponse(response.value);

      //   const direction = directionRouter();

      //   if (direction.params.vin) {
      //     router.push({
      //       name: direction.name,
      //       params: {
      //         vin: vin.value,
      //       },
      //     });
      //   } else {
      //     router.push({
      //       name: direction.name,
      //     });
      //   }
      // } else {
      //   if (send == "REQUEST_CANCELED") {
      //     return;
      //   } else {
      //     store.commit("updateMessage", "internal_error");
      //     router.push({
      //       name: "home",
      //     });
      //   }
      // }
    };

    onMounted(() => {
      document.title = 'Uzupełnij dane pojazdu';

      try {
        if (getVin.value.length > 0 && getPlate.value.length > 0 && getDate.value.length > 0) {
          store.commit('updateFillData', { vin: getVin.value, plate: getPlate.value, date: getDate.value });
        }
      } catch (error) {
        console.error(error);
      }
    });

    return {
      checkForm,
      vin,
      plate,
      date,
      disabledBtn,
      rulesCheckbox,
      checkedVin,
      MDBDatepicker,
      dataToAutofill,
      maxDate,
    };
  },
};
</script>

<style>
.form-outline .form-control~.form-notch .form-notch-leading,
.form-outline .form-control~.form-notch .form-notch-trailing,
.form-outline .form-control~.form-notch .form-notch-middle,
.form-outline .form-control:focus~.form-notch .form-notch-leading,
.form-outline .form-control:focus~.form-notch .form-notch-trailing,
.form-outline .form-control:focus~.form-notch .form-notch-middle {
  border: none;
}
</style>