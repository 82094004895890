<template>
  <MDBNavbar expand="lg" light bg="light">
    <div class="container">
      <MDBNavbarBrand class="navbar-brand me-2" aria-current="page" to="/">
        <router-link class="text-white" aria-current="page" to="/"
          ><img
            src="../assets/img/logo-easyvin.svg"
            height="60"
            width="228"
            class="ev-logo"
            alt="easyVIN - Identyfikacja i weryfikacja pojazdu"
            loading="lazy"
            style="margin-top: -6px" /></router-link
      ></MDBNavbarBrand>
      <MDBNavbarToggler
        @click="collapse1 = !collapse1"
        target="#navbarSupportedContent"
      ></MDBNavbarToggler>
      <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
        <MDBNavbarNav class="mb-2 mb-lg-0">
          <MDBNavbarItem to="/"> Strona główna </MDBNavbarItem>
          <MDBNavbarItem to="/przykladowy-raport/2ce3a022-5345-4b7f-bf51-ebb7e9c907e1"> Przykładowy raport </MDBNavbarItem>
          <MDBNavbarItem to="/kontakt"> Kontakt </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </div>
  </MDBNavbar>
</template>

<script>
import getSampleReport from "@/composables/getSampleReport";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
  },
  setup() {
    const collapse1 = ref(false);
    const store = useStore();
    const router = useRouter();
    const reportUuid = ref("");
    const reportData = ref({});

    const showSampleReport = async () => {
      const { req, response } = getSampleReport();
      const getSample = await req();

      if (getSample) {
        reportUuid.value = response.value.data.report.reportUuid;
        reportData.value[reportUuid.value] = response.value.data.report.result;
        store.commit("updateReportData", reportData.value);
      }

      router.push({
        name: "sampleReport",
        params: {
          uuid: reportUuid.value,
        },
      });
    };

    return {
      collapse1,
      showSampleReport,
    };
  },
};
</script>