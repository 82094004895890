<template>
  <section
    id="section-vehicle"
    class="ev-main-widget bg-light border rounded-5 mb-5 shadow-6-strong"
  >
    <div class="row gx-lg-2 gx-xl-4">
      <HeaderPanel
        title="Identyfikacja pojazdu"
        desc="Identyfikacja pojazdu na podstawie numeru nadwozia VIN"
      />
    </div>

    <DecoderSection :decoderData="decoderData" />
  </section>
</template>

<script>
import HeaderPanel from "../HeaderPanel";
import DecoderSection from "../decoderSection/DecoderSection";

export default {
  name: "VehicleInfoSection",
  components: {
    HeaderPanel,
    DecoderSection,
  },
  props: {
    decoderData: {
      type: Object,
      default: null,
    }
  }
};
</script>

<style>
</style>