<template>
  <section
  v-if="valuation"
    id="section-valuation"
    class="
      ev-main-widget
      bg-light bg-gradient
      border
      rounded-5
      mb-5
      shadow-6-strong
    "
  >
    <div class="row gx-lg-2 gx-xl-4">
      <HeaderPanel
        title="Szacunkowa wartość pojazdu"
        desc="Obliczenie szacunkowej rynkowej wartości pojazdu"
      />
    </div>
    <div class="row gx-lg-2 gx-xl-4 p-4">
      <div class="col">
        <div class="row gx-lg-2 gx-xl-4 mb-4 px-4 text-center">
          <Valuation :valuationData="valuation" />
        </div>

        <p class="fst-italic m-0">
          <strong>Uwaga!</strong> Dane do wyceny ograniczono do podstawowych
          parametrów rynkowych. Wartości wyceny
          zależą od faktycznego stanu pojazdu i mogą być bliższe najniższej lub
          najwyższej cenie rynkowej pojazdu. Podane kwoty są orientacyjne i nie
          mogą być traktowane jako końcowa wycena pojazdu.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderPanel from "../HeaderPanel";
import Valuation from "./Valuation";

export default {
  name: "ValuationSection",
  components: {
    HeaderPanel,
    Valuation,
  },
  props: {
    valuation: {
      type: Object
    }
  },
};
</script>

<style>
</style>