<template>
    <div
      class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-grey rounded-3 mb-4"
    >
      <div class="row d-flex flex-column align-items-center">
        <div class="col-12">
          <h3>Wpisany numer VIN:</h3>
          <h4>{{ getData.vin_orginal }}</h4>
          <p>Może być niepoprawny</p>
        </div>
  
        <div class="col-12 mb-4">
          <div class="row">
            <div
              class="col-sm-12 col-md-7 d-flex justify-content-center justify-content-md-end align-items-center"
            >
              <h5 v-html="'Prawidłowy' + ' ' + vinsValidator.vin_correct" class="m-0"></h5>
            </div>
            <div
              class="col-sm-12 col-md-5 d-flex justify-content-center justify-content-md-start mt-sm-2 mt-md-0"
            >
              <button
                @click="sendForm(getData.vin_correct)"
                class="btn btn-success"
              >
                Sprawdź
              </button>
            </div>
          </div>
        </div>
  
        <div class="col-12 mb-4">
          <div class="row">
            <div
              class="col-sm-12 col-md-7 d-flex justify-content-center justify-content-md-end align-items-center"
            >
              <h5 v-html="'Błędny' + ': ' + vinsValidator.vin_orginal" class="m-0"></h5>
            </div>
            <div
              class="col-sm-12 col-md-5 d-flex justify-content-center justify-content-md-start mt-sm-2 mt-md-0"
            >

              <button
                @click="redirectIncorrectVin(getData.vin_orginal)"
                class="btn btn-error"
              >
                Sprawdź
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useStore } from "vuex";
  import { computed, onMounted, ref } from "@vue/runtime-core";
//   import sendReq from "../composables/sendReq";
  import { useRouter } from "vue-router";
  
  export default {
    name: "VinValidator",
    components: {},
    setup() {
      // let vin = ref("");
      const store = useStore();
      const router = useRouter();
      const vin = computed(() => {
        return store.state.vin;
      });
  
      const vinsValidator = ref({
        vin_orginal: "",
        vin_correct: "",
      });
  
      const getData = computed(() => {
        return store.state.vinValidator;
      });
  
      onMounted(async () => {
        // const checkedVin = vinNumber.value || null;
  
        // if (checkedVin === null) {
        //   router.push({
        //     name: "home",
        //   });
        // }
      });
  
      const underscoreDiff = (vin, compare, color) => {
        let underscored = "";
  
        for (let i = 0; i < vin.length; i++) {
          if (vin[i] === compare[i]) {
            underscored += vin[i];
          } else {
            underscored += `<u class="${color}">${vin[i]}</u>`;
          }
        }
  
        return underscored;
      };
  
      vinsValidator.value.vin_orginal = underscoreDiff(
        getData.value.vin_orginal,
        getData.value.vin_correct,
        "text-danger"
      );
      vinsValidator.value.vin_correct = underscoreDiff(
        getData.value.vin_correct,
        getData.value.vin_orginal,
        "text-success"
      );
  
    //   const vinNumber = computed(() => {
    //     return store.state.checkedVin;
    //   });

  
      const sendForm = async (vin) => {
        if (vin) {
          store.dispatch("setCheckedVin", vin);
          router.push({
            name: "loader",
            params: {
              vin: vin,
            },
          });
        }
      };
  
      const redirectIncorrectVin = (vin) => {
        router.push({
          name: "incorrectVin",
          params: {
            vin,
          },
        });
      };
  
      return {
        vin,
        getData,
        vinsValidator,
        sendForm,
        redirectIncorrectVin,
      };
    },
  };
  </script>
  
  <style scoped>
  p {
    font-size: 18px;
  }
  .btn-error {
    background-color: #c81b17;
    color: white;
  }
  .btn-error:hover {
    background-color: #d01621;
    color: white;
  }
  </style>
  