<template>
  <div class="col-md-4">
    <div class="p-3 bg-success rounded-4 shadow-2-strong d-inline-block mb-4">
      <i :class="data.icon"></i>
    </div>
    <div
      class="pb-4 px-4 rounded-5"
      style="
        background: hsl(220, 33.3%, 98.2%);
        margin-top: -50px;
        padding-top: 50px;
      "
    >
      <h5 class="fw-bold mb-3">{{ data.title }}</h5>
      <p class="text-muted mb-0">
        {{ data.desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefit",
  props: {
      data: Object,
  }
};
</script>

<style>
</style>