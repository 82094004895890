<template>
  <section
    id="section-odometer"
    class="ev-main-widget bg-light border rounded-5 mb-5 shadow-6-strong"
  >
    <div class="row gx-lg-2 gx-xl-4">
      <HeaderPanel
        title="Odczyty drogomierza"
        desc="Chronologiczna lista odczytów drogomierza"
      />
    </div>
    <div class="row gx-lg-2 gx-xl-4 p-4">
      <div class="col-lg-8 col-print-12 mb-4 mb-lg-0">
        <div id="chartOdometer" class="rounded-4 shadow-2-strong bg-white">
          <Chart :data="chartData" />
        </div>
      </div>
      <div class="col-lg-4 col-print-12">
        <table class="table table-odometer rounded-4 shadow-2-strong bg-white">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Odczyt</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(odometer, date) in chartData" :key="odometer">
              <th scope="row">{{ date }}</th>
              <td>{{ odometer }}</td>
            </tr>
            <!-- <tr>
              <th scope="row">2014-08-19</th>
              <td>2.000 km</td>
            </tr>
            <tr>
              <th scope="row">2015-09-19</th>
              <td>13.845 km</td>
            </tr>
            <tr class="bg-warning">
              <th scope="row">2015-10-19</th>
              <td>12.225 km</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderPanel from "../HeaderPanel";
import Chart from './Chart';

export default {
  name: "OdometerSection",
  components: {
    HeaderPanel,
    Chart,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    }
  },
};
</script>

<style>
</style>