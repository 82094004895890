<template>
  <form @submit.prevent="checkForm" v-if="inputs == null && input != null">
    <div class="mb-4 text-center">
      <div class="form-text text-white text-center"><slot></slot></div>
      <div v-if="propData.div" class="pc-reg shadow-4-strong">
        <input
          :type="propData.type"
          :id="propData.id"
          :placeholder="propData.placeholder"
          :maxlength="propData.maxlength"
          :class="propData.classess"
          v-model="plate"
        />
      </div>

      <div v-else class="text-center">
        <input
          :type="propData.type"
          :id="propData.id"
          :placeholder="propData.placeholder"
          :maxlength="propData.maxlength"
          :class="propData.classess"
          v-model="vin"
        />
      </div>
      <span v-if="propData.div && wrongPlate" class="text-white fw-bold text-decoration-underline">Zły format tablicy rejestracyjnej</span>
    </div>

    <!-- Checkbox  -->
    <div class="form-check d-flex justify-content-center mb-4">
      <input
        class="form-check-input ev-toc me-2"
        type="checkbox"
        value=""
        :id="rule"
        v-model="rulesCheckbox"
      />
      <label class="form-check-label text-white" :for="rule">
        Zapoznalem się z Regulaminem serwisu
      </label>
    </div>

    <!-- Submit button -->
    <Button :disabled="!disabledBtn">SPRAWDŹ POJAZD</Button>
  </form>
</template>

<script>
import Button from "./Button";
// import sendReq from "@/composables/sendReq";
// import checkResponse from "@/composables/checkResponse";
import { useRouter } from "vue-router";
import { ref, toRef } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import regValidation from "../composables/regValidation";

export default {
  name: "Form",
  components: {
    Button,
  },
  props: {
    inputs: {
      type: Object,
      default: null,
    },
    input: {
      type: Object,
      default: null,
    },
    rule: String,
  },
  setup(props) {
    const data = props.input ?? props.inputs;
    const propData = toRef(data);
    const store = useStore();
    const vin = ref("");
    const plate = ref("");
    const date = ref("");
    const rulesCheckbox = ref(false);
    const wrongPlate = ref(false);
    // const token = ref("TOKEN");
    const router = useRouter();

    const disabledBtn = computed(() => {
      return (
        (vin.value.length == 17 || plate.value.length > 4) &&
        rulesCheckbox.value === true
      );
    });

    const checkForm = async () => {
      if (
        (vin && vin.value.length === 17) ||
        (plate && plate.value.length > 5)
      ) {
        disabledBtn.value = false;

        if (vin.value) {
          store.dispatch("setCheckedVin", vin.value);
          router.push({
            name: "loader",
            params: {
              vin: vin.value,
            },
          });
        } else {
          if (plate.value.length > 0) {
            const validatePlate = regValidation(plate.value);
            wrongPlate.value = !validatePlate;

            if (!validatePlate) {
              return false;
            }
          }
          store.dispatch("setCheckedPlate", plate.value);
          router.push({
            name: "loader",
            params: {
              plate: plate.value,
            },
          });
        }
      }
    };

    return {
      checkForm,
      propData,
      vin,
      plate,
      date,
      rulesCheckbox,
      disabledBtn,
      wrongPlate,
    };
  },
};
</script>

<style>
</style>