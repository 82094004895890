<template>
  <div>
    <div class="container mt-0 mt-md-2 p-0 p-md-3">
      <!-- Section: Design Block -->
      <section class="ev-main-widget ev-bg-anim rounded-6 shadow-6-strong">
        <div
          class="d-flex justify-content-center pt-3 px-3 rounded-6"
          style="background-color: #00000033"
        >
          <div class="text-center text-white" style="max-width: 700px">
            <h2 class="fw-bold mb-3">Historia pojazdu po numerze VIN</h2>
            <p class="mb-3">
              Historia auta od a do z w jedym miejscu. Zweryfikuj swoją wiedzę na temat pojazdu którym jesteś zainteresowany i oszczędź sobie przykrych niespodzianek przy zakupie nowego auta.
            </p>
          </div>
        </div>

        <div class="row gx-lg-5 p-4 p-md-5">
          <div class="col-lg-5 mb-4 mb-lg-0 align-self-start">
            <TabsNav />
          </div>

          <SquareSection />
        </div>
      </section>
      <!-- Section: Design Block -->
    </div>

    <div class="container my-5">
      <!-- Section: Design Block -->
      <section class="text-center">
        <div class="d-flex justify-content-center">
          <div class="col-12">
            <h2 class="fw-bold mb-4">Co znajdziesz w raporcie?</h2>
          </div>
        </div>

        <div class="row gx-lg-5 mb-4">
          <BenefitsSection />
        </div>

        <WhatIsVin />
      </section>
      <!-- Section: Design Block -->
    </div>
  </div>
</template>

<script>
import TabsNav from "../components/TabsNav";
import SquareSection from "../components/sections/squareSection/SquareSection";
import BenefitsSection from "../components/sections/benefitsSection/BenefitsSection";
import WhatIsVin from "../components/WhatIsVin";
import { onMounted } from '@vue/runtime-core';

export default {
  name: "Home",
  components: {
    TabsNav,
    SquareSection,
    BenefitsSection,
    WhatIsVin,
  },
  setup() {
    onMounted(() => {
      document.title = 'Darmowa identyfikacja i weryfikacja pojazdu po numerze VIN - dekoder VIN';
    });
  }
};
</script>

<style>
</style>