<template>
  <div class="container mt-0 mt-md-2 p-0 p-md-3 text-center">
    <img class="img-fluid" src="../../assets/img/easyvin-contact.png" alt="">
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
export default {
  name: "Contact",
  setup() {
    onMounted(() => {
      document.title = 'Kontakt z obsługą serwisu';
    });
  }
};
</script>