<template>
  <div class="col-lg-7 mt-4 mt-lg-0 mb-4 mb-md-0 align-self-center">
    <div class="row gx-lg-5">
      <Square v-for="(data, index) in squareData" :data="data" :key="index" />
    </div>

    <div class="row gx-lg-5 mt-4 text-center">
      <div class="col">
        <!-- <a href="/przykladowy-raport">kliuk</a> -->
        <button
          @click="showSampleReport"
          title="Raport Historii Pojazdu"
          class="btn btn-light rounded-pill shadow-2-strong"
          data-mdb-ripple-color="dark"
          target="_blank"
          rel="noopener"
        >
          Zobacz przykładowy raport
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Square from "./Square";
import getSampleReport from "@/composables/getSampleReport";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref } from '@vue/reactivity';
// import { computed } from "@vue/runtime-core";

export default {
  name: "SquareSection",
  components: {
    Square,
  },
setup() {
    const squareData = {
      vehicleHistory: {
        icon: "fas fa-car-crash fa-lg ev-text-orange fa-fw",
        title: "Historia pojazdu",
        desc: "Dane rejestrowe oraz archiwalny spis przeglądów technicznych",
      },
      vehicleOdometers: {
        icon: "fas fa-list-ol fa-lg ev-text-orange fa-fw",
        title: "Historia przebiegu",
        desc: "Odnotowane stany licznika przez cały okres eksploatacji pojazdu",
      },
      technicalData: {
        icon: "fas fa-car-side fa-lg ev-text-orange fa-fw",
        title: "Dane techniczne",
        desc: "Parametry auta i specyfikacja techniczna",
      },
      andMore: {
        icon: "fas fa-ellipsis-h fa-lg ev-text-orange fa-fw",
        title: "I jeszcze więcej ...",
        desc: "informacji o pojeździe",
      },
    };

    const store = useStore();
    const router = useRouter();

    const reportUuid = ref("");
    const reportData = ref({});

    const showSampleReport = async () => {
      const { req, response } = getSampleReport();
      const getSample = await req();

      if (getSample) {
        // vin = response.value.data.report.result.vehicle_identification.vin;
        reportUuid.value = response.value.data.report.reportUuid;
        reportData.value[reportUuid.value] = response.value.data.report.result;
        // reportData.value[response['data']['reportUuid']] = response.data.result;
        store.commit("updateReportData", reportData.value);
      }

      router.push({
        name: "sampleReport",
        params: {
          uuid: reportUuid.value,
        },
      });
    };

    return {
      squareData,
      showSampleReport,
    };
  },
};
</script>

<style>
</style>