<template>
  <div class="container my-2">
    <!-- Section: Design Block -->
    <section>
      <div class="row mb-5">
        <div class="col-12">
          <h1 class="fw-bold my-4">Regulamin serwisu</h1>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">I. Wstęp</h3>
          <ol>
            <li class="mb-3">
              Niniejszy Regulamin określa zasady korzystania ze strony
              internetowej prowadzonej pod nazwą "EasyVIN”, w domenie
              internetowej "www.easyvin.pl" (dalej zwanej "Stroną"). Jeśli nie
              wyrażasz zgody na stosowanie niniejszego Regulaminu lub
              któregokolwiek z jego postanowień, powinieneś natychmiast opuścić
              Stronę
            </li>
            <li class="mb-3">
              Pełna nazwa naszej firmy: "AutoISO Sp. z o.o.". Nasz numer
              identyfikacji podatkowej w Polsce (NIP-PL): PL6342976575. Nasz
              adres prowadzenia działalności: ul. Gnieźnieńska 12, 40-142
              Katowice (Polska). Można się z nami kontaktować poprzez
              wypełnienie formularza na podstronie "Kontakt" na Stronie lub za
              pośrednictwem adresu e-mail: easyvin@autoiso.pl.
            </li>
            <li class="mb-3">
              Głównym celem Strony jest udostępnienie jej użytkownikom jak
              największej ilości danych technicznych i historycznych określonego
              pojazdu (zob. definicję Danych Pojazdu w punkcie II Regulaminu) na
              podstawie podanych przez użytkowników podstawowych danych
              identyfikujących ten pojazd. Dane Pojazdu uzyskujemy z Bazy Danych
              (zob. definicję w punkcie II Regulaminu). Pomimo naszych starań,
              aby Dane Pojazdu stanowiły rzetelne źródło wiedzy o określonym
              pojeździe, nie jest możliwe pełne zweryfikowanie ich zgodności z
              aktualnym stanem faktycznym, dlatego mogą powielać błędy źródłowe
              Bazy Danych i odbiegać od aktualnego stanu faktycznego. W związku
              z tym Dane Pojazdu należy traktować jedynie jako narzędzie
              pomocnicze do oceny historii i stanu danego pojazdu, a następnie
              zawsze bezpośrednio zbadać aktualny stan faktyczny tego pojazdu i
              jego dokumenty, szczególnie przed podjęciem decyzji o jego
              nabyciu. Dostęp do Danych Pojazdu może być odpłatny. Dane Pojazdu
              nie zawierają żadnych danych osobowych. Rodzaj informacji
              zawartych w Danych Pojazdu może się różnić dla każdego pojazdu.
              Możliwość uzyskania określonych informacji zależy od tego, czy
              informacje te są dostępne w Bazie Danych. Przed wygenerowaniem
              Danych Pojazdu istnieje możliwość sprawdzenia, czy Baza Danych
              zawiera określony rodzaj informacji.
            </li>
            <li class="mb-3">
              Usługi dostępne na Stronie przeznaczone są dla osób fizycznych,
              osób prawnych i innych podmiotów, które mają miejsce zamieszkania
              lub siedzibę w granicach geograficznych Europy (w szczególności
              państw Europejskiego Obszaru Ekonomicznego) oraz Ameryki
              Północnej.
            </li>
            <li class="mb-3">
              Usługi na Stronie są kierowane wyłącznie do osób mających
              ukończone 18 lat.
            </li>
            <li class="mb-3">
              Zasady przetwarzania przez nas danych osobowych i używania plików
              zwanych ciasteczkami (cookies) przedstawione są w naszej Polityce
              Prywatności dostępnej na Stronie.
            </li>
            <li class="mb-3">
              By korzystać ze Strony użytkownik musi używać urządzenia mającego
              dostęp do Internetu, z prawidłowo zainstalowaną i skonfigurowaną
              przeglądarką internetową w najaktualniejszej wersji. By korzystać
              z niektórych funkcjonalności Strony należy korzystać z
              oprogramowania Java i Java Script, jak i zezwolić na używanie
              ciasteczek. Nie ponosimy odpowiedzialności za brak dostępu do
              danych zamieszczonych na naszej Stronie w wyniku braku zgodności
              urządzenia użytkownika z wymaganiami technicznymi wskazanymi
              powyżej.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">II. Definicje</h3>
          <p>
            W niniejszym Regulaminie terminom wskazanym poniżej przypisuje się
            następujące znaczenie:
          </p>
          <ul class="ms-4 list-unstyled">
            <li class="mb-3">
              <strong>"Dane Pojazdu"</strong> oznaczają zbiór różnego rodzaju
              danych o określonym pojeździe uzyskanych z Bazy Danych,
              udostępniany za pośrednictwem Strony w formie raportu (dane te
              mogą zawierać informacje odnośnie historii pojazdu, danych
              technicznych, środków poprawy bezpieczeństwa pojazdu itp.), przy
              czym dane te należy traktować jedynie jako narzędzie pomocnicze do
              oceny historii i stanu danego pojazdu, ponieważ nie są one w pełni
              zweryfikowanie co do zgodności z aktualnym stanem faktycznym tego
              pojazdu.
            </li>
            <li class="mb-3">
              <strong>"Konsument"</strong> oznacza każdą osobę fizyczną, która
              działa w celach niezwiązanych z działalnością gospodarczą lub
              zawodową.
            </li>
            <li class="mb-3">
              <strong>"Baza Danych"</strong> oznacza wszelkie dostępne nam
              źródła Danych Pojazdu, w szczególności naszą bazę danych i bazy
              danych, z którymi współpracujemy,
            </li>
          </ul>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">
            III. Licencja do używania Strony i dostępnych na niej materiałów
          </h3>
          <ol>
            <li class="mb-3">
              Z zastrzeżeniem innych postanowień, wszelkie prawa własności
              intelektualnej do Strony oraz do materiałów na Stronie przysługują
              nam lub naszym licencjodawcom.
            </li>
            <li class="mb-3">
              <p>Zakazane są w szczególności:</p>
              <ol>
                <li class="mb-3">
                  dystrybucja materiałów uzyskanych ze Strony (wliczając
                  dystrybucję na innej stronie internetowej);
                </li>
                <li class="mb-3">
                  sprzedaż, najem, użyczenie, darowanie, przekazywanie albo
                  udzielenie sublicencji odnośnie Strony lub materiałów ze
                  Strony;
                </li>
                <li class="mb-3">
                  odtwarzanie publiczne jakichkolwiek materiałów ze Strony;
                </li>
                <li class="mb-3">
                  reprodukowanie, zwielokrotnienie, kopiowanie lub inne
                  korzystanie z materiałów ze Strony w celach komercyjnych; lub
                </li>
                <li class="mb-3">
                  zmienianie lub w inny sposób modyfikowanie jakichkolwiek
                  materiałów na Stronie;
                </li>
              </ol>
              <p>bez uzyskania naszej uprzedniej i wyraźnej pisemnej zgody.</p>
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">IV. Użytkowanie Strony</h3>
          <ol>
            <li class="mb-3">
              Zakazane jest używanie Strony w jakikolwiek sposób szkodliwy dla
              niej lub wpływający negatywnie na możliwość korzystania ze Strony;
              lub w jakikolwiek inny sposób, który jest nielegalny, bezprawny,
              szkodliwy lub powiązany z jakimkolwiek nielegalnym, bezprawnym lub
              szkodliwym celem lub działaniem. Zastrzegamy sobie wszelkie prawa
              do wszelkich informacji i materiałów, w tym graficznych i innych,
              dostępnych na Stronie.
            </li>
            <li class="mb-3">
              Zakazane jest korzystanie ze Strony w celu kopiowania,
              korzystania, przechowywania, transmitowania, wysyłania,
              publikowania lub dystrybuowania jakichkolwiek materiałów, które
              zawierają jakiegokolwiek oprogramowanie szpiegowskie, wirusa,
              konia trojańskiego, robaka, keystroke logger, rootkit lub inne
              szkodliwe oprogramowanie lub urządzenia, lub odwołują się do nich.
            </li>
            <li class="mb-3">
              Zakazane jest jakiekolwiek systematyczne lub automatyczne
              pozyskiwanie danych ze Strony lub danych powiązanych ze Stroną (w
              tym za pomocą następujących technik: scraping, data mining, data
              extraction i data harvesting) bez naszej pisemnej zgody.
            </li>
            <li class="mb-3">
              Zakazane jest korzystanie ze Strony w celu transmisji lub
              wysyłania niezamówionej informacji handlowej.
            </li>
            <li class="mb-3">
              Zakazane jest wykorzystywanie Strony do jakichkolwiek celów
              marketingowych bez naszej pisemnej zgody.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">V. Ograniczony dostęp</h3>
          <ol>
            <li class="mb-3">
              Dostęp do części zasobów Strony jest ograniczony. Mamy prawo do
              ograniczenia dostępu do innych zasobów Strony lub ograniczenia
              dostępu do całej Strony, z zastrzeżeniem uprawnień użytkowników
              wynikających z wcześniej zawartych umów.
            </li>
            <li class="mb-3">
              W przypadku uzyskania od nas przez użytkownika loginu lub loginu i
              hasła umożliwiających dostęp do zasobów z ograniczonym dostępem
              znajdujących się na Stronie lub innych zawartości lub usług z
              ograniczonym dostępem, użytkownik jest zobowiązany do
              zabezpieczenia hasła i loginu, by nie został on ujawniony żadnej
              osobie trzeciej.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">
            VI. Zawartość zamieszczona przez Użytkownika
          </h3>
          <ol>
            <li class="mb-3">
              Zawartość zamieszczona przez użytkownika na Stronie nie może być
              niezgodna z prawem, zasadami współżycia społecznego, Netykietą i
              innymi ustalonymi zwyczajami lub naruszać jakiekolwiek prawa osób
              trzecich, ani nie może dawać podstaw do wystąpienia z jakimkolwiek
              roszczeniem lub środkiem prawnym przeciwko użytkownikowi, nam lub
              osobie trzeciej (w każdym przypadku, w zakresie każdego prawa
              właściwego).
            </li>
            <li class="mb-3">
              Zakazane jest umieszczanie na Stronie jakichkolwiek treści, które
              są lub były przedmiotem jakiegokolwiek toczącego się obecnie lub
              grożącego w przyszłości postępowania spornego lub podobnego.
            </li>
            <li class="mb-3">
              Zastrzegamy sobie prawo do zmiany lub usunięcia jakichkolwiek
              treści lub materiału zamieszczonego na Stronie, przechowywanego
              lub opublikowanego na niej.
            </li>
            <li class="mb-3">
              Z zastrzeżeniem przepisów niniejszego Regulaminu i obowiązującego
              prawa w odniesieniu do treści i innych materiałów umieszczonych na
              Stronie przez użytkownika, nie jesteśmy zobowiązani do
              monitorowania zamieszczanych lub publikowanych na Stronie treści i
              materiałów.
            </li>
            <li class="mb-3">
              Wszelkie dane zamieszczone przez użytkownika na Stronie powinny
              być również zachowane na jego własnych urządzeniach w celu
              dodatkowego zabezpieczenia przed ich utratą.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">
            VII. Udostępnianie Danych Pojazdu oraz płatności
          </h3>
          <ol>
            <li class="mb-3">
              Korzystanie z bezpłatnych usług może wiązać się z koniecznością
              podania adresu e-mail, numeru VIN, tablicy rejestracyjnej oraz
              daty pierwszej rejestracji pojazdu.
            </li>
            <li class="mb-3">
              Dostęp do niektórych zasobów Strony oraz generowanie Danych
              Pojazdu są odpłatne. W takim wypadku płatności dokonywane są
              wyłącznie przez serwis płatniczy wskazany na Stronie (taki jak
              PayPal lub PayU) z użyciem karty kredytowej lub w inny sposób tam
              wskazany.
            </li>
            <li class="mb-3">
              Aby otrzymać Dane Pojazdu należy najpierw wskazać podstawowe dane
              identyfikujące pojazd (określone w formularzach na Stronie) w celu
              uzyskania informacji, czy posiadamy dane dotyczące konkretnego
              pojazdu. Następnie należy zarejestrować się na Stronie i wybrać
              jedną z opcji otrzymania Danych Pojazdów, aby umożliwić nam
              świadczenie wskazanych usług. Umowę między użytkownikiem a nami o
              udostępnienie Danych Pojazdu z Bazy Danych uznaje się za zawartą w
              chwili dokonania rejestracji przez użytkownika. Niniejszy
              Regulamin stanowi część tej umowy.
            </li>
            <li class="mb-3">
              Płatności za usługi dostępne na Stronie są wykonywane za
              pośrednictwem serwisów płatniczych wskazanych na Stronie (jak
              PayPal lub PayU). W związku z tym płatności te podlegają
              regulaminom serwisów płatniczych dostępnym na ich stronach
              internetowych.
            </li>
            <li class="mb-3">
              Ceny za poszczególne rodzaje Danych Pojazdu są podane na Stronie.
            </li>
            <li class="mb-3">
              Dane Pojazdu są generowane automatycznie oraz, z zastrzeżeniem
              ograniczeń wskazanych na Stronie, są dostępne niezwłocznie po
              zawarciu przez nas i użytkownika umowy o udostępnianie Danych
              Pojazdu. Opóźnienia w generowaniu Danych Pojazdu związane z
              problemami technicznymi spowodowanymi przez osobę trzecią, awarię
              lub prace konserwacyjne albo aktualizację oprogramowania nie
              stanowią naruszenia umowy o udostępnienie Danych Pojazdu.
            </li>
            <li class="mb-3">
              Zakupione Dane Pojazdu są udostępniane na Stronie przez 30 dni. Po
              tym terminie mogą zostać całkowicie usunięte ze Strony.
            </li>
            <li class="mb-3">
              Na Stronie mogą być także udostępnione dodatkowe usługi związane z
              Danymi Pojazdu, w szczególności usługa automatycznej wyceny
              pojazdu, którego dotyczą Dane Pojazdu. W takim przypadku do usług
              tych stosuje się odpowiednio przepisy niniejszego Regulaminu
              dotyczące usługi udostępnienia danych pojazdu, w szczególności w
              zakresie nabycia usługi (punkt VII Regulaminu) oraz zakresu naszej
              odpowiedzialności za tę usługę (punkty VIII-X Regulaminu).
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">
            VIII. Ograniczone oświadczenia i zapewnienia oraz zgłaszanie
            reklamacji
          </h3>
          <ol>
            <li class="mb-3">
              Na podstawie umowy z użytkownikiem o udostępnienie Danych Pojazdu
              jesteśmy zobowiązani do zapewnienia dostępu do Danych Pojazdu,
              jakie znajdują się w danej chwili w Bazie Danych. Dane Pojazdu
              stanowią jedynie pomoc i wskazówki dla użytkownika, a Baza Danych
              nie jest urzędową bazą danych. W związku z tym, z powodów
              obiektywnych, nie jesteśmy w stanie zagwarantować, że Dane Pojazdu
              są kompletne, aktualne i w pełni zgodne ze stanem faktycznym.
            </li>
            <li class="mb-3">
              Generujemy Dane Pojazdu wyłącznie na podstawie danych, które
              posiadamy w Bazie Danych w momencie zlecenia udostępnienia Danych
              Pojazdu. Dlatego w przypadku korzystania z usług na Stronie może
              się zdarzyć, że niektóre oczekiwane dane nie będą dostępne, a
              udostępnione Dane Pojazdu mogą nie być aktualne. Nie ponosimy za
              to odpowiedzialności.
            </li>
            <li class="mb-3">
              Jeśli specyfikacja podstawowych informacji o pojeździe zawarta w
              Danych Pojazdu oparta jest na odkodowaniu danych z numeru VIN
              nadanego temu pojazdowi przez producenta, nie wykaże ona zmian
              dokonanych w określonym pojeździe po jego wyprodukowaniu.
            </li>
            <li class="mb-3">
              W przypadku użytkowników niebędących Konsumentami, w granicach
              przewidzianych prawem właściwym, nie składamy żadnych zapewnień,
              nie udzielamy gwarancji co do jakości, wyłączamy rękojmię oraz nie
              składamy zapewnień co do standardów wykonywania usług na Stronie i
              korzystania ze Strony (w szczególności wyłączamy wszelkie
              gwarancje, nie składamy żadnych zapewnień przewidzianych w
              obowiązującym prawie odnośnie satysfakcjonującej jakości,
              przydatności do celu lub zachowania należytej staranności i
              umiejętności) oraz nie gwarantujemy prawidłowości odkodowania
              podstawowych danych identyfikujących pojazd, w szczególności
              numeru VIN i tablicy rejestracyjnej.
            </li>
            <li class="mb-3">
              W przypadku wystąpienia problemów technicznych dotyczących Strony,
              albo gdy udostępniane przez nas Dane Pojazdu są niezgodne ze
              stanem faktycznym lub nieaktualne, albo w celu zgłoszenia skarg
              dotyczących Strony (reklamacje), należy niezwłocznie nas o tym
              powiadomić lub zgłosić skargę przez użycie formularza na
              podstronie "Kontakt" Strony, lub przez napisanie do nas wiadomości
              na adres e-mail wskazany powyżej we Wstępie Regulaminu. Staramy
              się odpowiadać niezwłocznie.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">IX. Wyłączenie odpowiedzialności</h3>
          <ol>
            <li class="mb-3">
              Żadne postanowienie w niniejszym Regulaminie nie ogranicza ani nie
              wyłącza odpowiedzialności naszej lub użytkownika w jakikolwiek
              sposób sprzeczny z prawem; w szczególności Regulamin nie wyłącza
              odpowiedzialności naszej lub użytkownika za szkody spowodowane
              winą umyślną.
            </li>
            <li class="mb-3">
              <p>
                Wyłączenia i ograniczenia odpowiedzialności przewidziane w
                Regulaminie:
              </p>
              <ol>
                <li class="mb-3">
                  podlegają ograniczeniom wskazanym w ustępie 1 powyżej;
                </li>
                <li class="mb-3">
                  dotyczą wszelkiej odpowiedzialności mogącej powstać na gruncie
                  Regulaminu lub Polityki Prywatności w związku z zakresem
                  przedmiotowym Regulaminu i Polityki Prywatności, wliczając
                  odpowiedzialność kontraktową, deliktową lub inną jej podobną;
                </li>
                <li class="mb-3">nie dotyczą Konsumentów.</li>
              </ol>
            </li>
            <li class="mb-3">
              <p>
                W przypadku użytkowników niebędących Konsumentami, tak dalece
                jak jest to możliwe w ramach prawa właściwego:
              </p>
              <ol>
                <li class="mb-3">
                  nie odpowiadamy za żadne szkody jakiegokolwiek rodzaju, chyba
                  że szkoda powstała z naszej winy umyślnej. Rękojmia oraz
                  jakakolwiek odpowiedzialność wynikająca z instytucji podobnych
                  do rękojmi zostaje niniejszym wyłączona;
                </li>
                <li class="mb-3">
                  nie odpowiadamy za żadną utratę lub uszkodzenie jakichkolwiek
                  danych, bazy danych lub oprogramowania zarówno naszego, jak i
                  użytkownika.
                </li>
              </ol>
            </li>
            <li class="mb-3">
              Nie odpowiadamy za jakiekolwiek szkody powstałe w związku ze
              zdarzeniem lub zdarzeniami pozostającymi poza naszą kontrolą.
            </li>
            <li class="mb-3">
              Dane Pojazdu są udostępniane wyłącznie użytkownikowi będącemu
              stroną umowy z nami o udostępnienie tych danych i nie powinny być
              upubliczniane bez naszej uprzedniej zgody
            </li>
            <li class="mb-3">
              W przypadku gdy informacje zawarte w Danych Pojazdu pochodzą z
              National Motor Vehicle Title Information System (NMVTIS),
              korzystanie ze Strony oznacza zaakceptowanie ograniczeń
              odpowiedzialności wskazanych przez NMVTIS, które składają się na
              postanowienia niniejszego Regulaminu i są dostępne pod adresem
              http://vehiclehistory.gov/nmvtis/, jak i na Stronie. W przypadku
              gdy informacje zawarte w Danych Pojazdu pochodzą z baz danych
              National Highway Traffic Safety Administration (NHTSA) lub Vehicle
              & Operator Services Agency (VOSA), korzystanie ze Strony oznacza
              zaakceptowanie regulaminów tych baz danych, chyba że postanowienia
              tych regulaminów dotyczą wyłącznie bezpośrednich użytkowników tych
              stron.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">X. Zwolnienie ze zobowiązań</h3>
          <p>
            Użytkownicy nie będący Konsumentami zwalniają nas od rekompensaty
            wszelkich strat, szkód, odszkodowań, kosztów, odpowiedzialności i
            wydatków (w tym kosztów związanych z jakimikolwiek postępowaniami
            oraz każdej zapłaconej przez nas osobie trzeciej kwoty tytułem
            ugody) związanych z jakimkolwiek naruszeniem przez użytkownika
            niniejszego Regulaminu, oraz są zobowiązani do zwrócenia nam
            wszystkich poniesionych przez nas w związku z tym kosztów.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XI. Naruszenia Regulaminu</h3>
          <p>
            Z zastrzeżeniem wszelkich innych naszych uprawnień wynikających z
            niniejszego Regulaminu, w przypadku naruszenia przez użytkownika w
            jakikolwiek sposób niniejszego Regulaminu, możemy podjąć takie
            działania, jakie uznamy za właściwe w celu reakcji na naruszenie, a
            umożliwiające powstrzymanie dalszego naruszenia oraz ograniczenie
            powstania dalszej szkody, wliczając w to zawieszenie dostępu
            użytkownika do Strony, uniemożliwienie dostępu do Strony
            użytkownikowi, zablokowanie dostępu do Strony dla danego adresu IP,
            skontaktowanie się z provider-em Internetu użytkownika w celu
            zablokowania dostępu do Strony lub skierowanie sprawy na drogę
            sądową.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XII. Zmiana postanowień Regulaminu</h3>
          <ol>
            <li class="mb-3">
              Regulamin może być zmieniany przez umieszczenie jego nowej wersji
              na Stronie. W przypadku gdy użytkownik udostępni nam swoje dane
              osobowe, w szczególności adres e-mail, i gdy będziemy świadczyli
              na jego rzecz usługi w trakcie zmiany niniejszego Regulaminu,
              poinformujemy go o wszelkich zmianach niniejszego Regulaminu za
              pośrednictwem poczty e-mail.
            </li>
            <li class="mb-3">
              W przypadku użytkowników niebędących Konsumentami zmiana naszych
              danych kontaktowych nie stanowi zmiany niniejszego Regulaminu.
            </li>
            <li class="mb-3">
              W przypadku zmiany adresu e-mail wskazanego podczas rejestracji
              użytkownicy są zobowiązani do uaktualnienia tego adresu na
              Stronie.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XIII. Cesja</h3>
          <p>
            Użytkownicy nie mogą przenosić, udostępnić innym lub w inny sposób
            rozporządzać swoimi prawami i obowiązkami wynikającymi z niniejszego
            Regulaminu, jak i zaciągać zobowiązań odnośnie tych praw i
            obowiązków.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XIV. Klauzula salwatoryjna</h3>
          <p>
            W przypadku gdy którekolwiek postanowienie niniejszego Regulaminu
            zostanie uznane przez sąd lub inny właściwy organ za nieważne lub
            bezskuteczne, pozostałe postanowienia Regulaminu pozostają wiążące.
            Jeżeli nieważne lub bezskuteczne postanowienie byłoby ważne po
            usunięciu jego części, ta część będzie uznana za usuniętą, a
            pozostała część postanowienia będzie uznawana za obowiązującą.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XV. Wyłączenie praw osób trzecich</h3>
          <p>
            Regulamin określa wyłącznie relacje między nami a użytkownikami
            Strony i nie przewiduje żadnych uprawnień dla osób trzecich ani nie
            uprawnia ich do powoływania się na którekolwiek z jego postanowień
            lub żądania wykonania tych postanowień. Wykonanie postanowień
            Regulaminu nie wymaga uzyskania zgody jakiejkolwiek osoby trzeciej.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XVI. Zakres umowy</h3>
          <p>
            Niniejszy Regulamin, łącznie z naszą Polityką Prywatności i cenami
            usług wskazanymi na Stronie, stanowi umowę między nami a
            użytkownikiem odnośnie korzystania ze Strony i zastępuje wszelkie
            wcześniejsze regulacje dotyczące korzystania ze Strony.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">XVII. Prawo właściwe i jurysdykcja</h3>
          <ol>
            <li class="mb-3">
              Niniejszy Regulamin podlega prawu polskiemu, a w zakresie
              nieuregulowanym w Regulaminie zastosowanie znajdują w
              szczególności ustawa z 23 kwietnia 1964 r. Kodeks cywilny, ustawa
              z 30 maja 2014 r. o prawach konsumenta (w odniesieniu do
              Konsumentów) i ustawa z 18 lipca 2002 r. o świadczeniu usług drogą
              elektroniczną.
            </li>
            <li class="mb-3">
              W przypadku użytkowników niebędących Konsumentami wszelkie spory
              powstałe na gruncie Regulaminu podlegać będą wyłącznej jurysdykcji
              polskich sądów.
            </li>
            <li class="mb-3">
              W przypadku użytkowników będących Konsumentami, których miejsce
              zamieszkania jest zlokalizowane w Polsce w dacie zawarcia umowy o
              udostępnienie Danych Pojazdu, wszelkie spory związane z niniejszym
              Regulaminem lub Polityką Prywatności podlegać będą wyłącznej
              jurysdykcji sądów polskich.
            </li>
          </ol>
        </div>
      </div>
    </section>
    <!-- Section: Design Block -->
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
export default {
  name: "Rules",
  setup() {
    onMounted(() => {
      document.title = 'Regulamin serwisu';
    });
  }
};
</script>