<template>
  <div class="col-md-6 col-lg-3 col-print-3 my-2">
    <div
      class="p-2 rounded-4 shadow-2-strong d-inline-block mb-4"
      :class="'bg-' + tile.status"
    >
      <i
        class="fas fa-2x text-white fa-fw"
        :class="'fa-' + (tile.status == 'success' ? 'check' : 'times')"
      ></i>
    </div>
    <div
      class="pb-4 px-4 rounded-5 shadow-2-strong h-90 border"
      :class="'border-' + (tile.status == 'success' ? 'success' : 'danger')"
      style="
        background: hsl(220, 33.3%, 98.2%);
        margin-top: -50px;
        padding-top: 50px;
      "
    >
      <h5 class="fw-bold mb-3">{{ name }}</h5>
      <p class="lead" :class="'text-' + tile.status">
        <strong>{{ tile.value }}</strong>
      </p>
      <p class="m-0">{{ tile.annotationTitle }}:<br v-if="name != 'Kradzież'" /> <strong v-if="tile.annotationDesc == 'Vehicle registration certificate'">Dowód rejestracyjny</strong>
      <strong v-else>{{ tile.annotationDesc }}</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBlock",
  props: {
    tile: {
      type: String,
      default: 'success',
    },
    name: String,
    tiles: Object,
  },
};
</script>

<style>
</style>