<template>
  <section
    id="section-header"
    class="ev-main-widget ev-bg-anim rounded-6 shadow-6-strong"
  >
    <div
      class="d-flex justify-content-center pt-3 px-3 rounded-6"
      style="background-color: #00000033"
    >
      <div class="text-center text-white" style="max-width: 700px">
        <h1 class="fw-bold mb-3">Raport Historii Pojazdu</h1>
        <h2 class="fw-bold mb-3">
          <span class="ident-year d-block d-sm-inline"
            >{{ " " + vehicleInfo.first_section["Rok produkcji"] }}
          </span>
          <span class="ident-make d-block d-sm-inline">{{
            " " + vehicleInfo.first_section["Marka"]
          }}</span>
          <span class="ident-model d-block d-sm-inline">{{
            " " + vehicleInfo.first_section["Model"]
          }}</span>
        </h2>
        <h3 class="fw-bold mb-3">{{ vehicleInfo.vin }}</h3>
      </div>
    </div>

    <div class="row gx-lg-2 gx-xl-4 mb-2 px-4 text-center">
      <!-- infoBlock component start -->
      <InfoBlock
        v-for="(tile, name) in tiles"
        :key="name"
        :tile="tile"
        :name="name"
      />

      <!-- <div class="col-md-6 col-lg-3 col-print-3 my-2">
        <div
          class="p-2 bg-danger rounded-4 shadow-2-strong d-inline-block mb-4"
        >
          <i class="fas fa-times fa-2x text-white fa-fw"></i>
        </div>
        <div
          class="pb-4 px-4 rounded-5 h-90 shadow-2-strong border border-danger"
          style="
            background: hsl(220, 33.3%, 98.2%);
            margin-top: -50px;
            padding-top: 50px;
          "
        >
          <h5 class="fw-bold mb-3">Badanie techniczne</h5>
          <p class="lead text-danger"><strong>NIEAKTUALNE</strong></p>
          <p class="m-0">
            Data ważności:<br /><span class="text-danger"
              ><strong>2022-01-15</strong></span
            >
          </p>
        </div>
      </div> -->
      <!-- infoblock component end -->
    </div>

    <div class="mx-2 p-4 mb-5">
      <div class="row">
        <div class="col-lg-8 align-self-center">
          <div class="row mb-0 bg-white rounded-5 shadow-5 p-4 pb-3 h-100">
            <dl v-for="(data, index) in additionalData" :key="index" class="d-flex m-0 flex-wrap">
              <dt
                v-if="
                  data &&
                  data.name
                "
                v-html="data.name + ':'"
                class="col-md-4 col-12 text-start text-md-end"
              >
              </dt>
              <dd
                v-if="
                  data &&
                  data.desc
                "
                class="col-md-8 col-12 ps-md-2"
              >
                <i :class="data.icon"></i>
                {{ data.desc || "-" }}
              </dd>
            </dl>
          </div>
        </div>

        <div class="col-lg-4 align-self-center">
          <div
            class="
              mt-4 mt-lg-0
              p-4
              border border-dark
              rounded-5
              shadow-2-strong
              h-100
              bg-dark bg-gradient
              text-white text-center
            "
          >
            <h5 class="fw-bold mb-2">
              Mamy więcej informacji dla tego pojazdu!
            </h5>
            <div class="row">
              <div class="col-12">
                <div class="row mb-4">
                  <div class="col-12">
                    <i
                      class="fas fa-chevron-circle-down fa-6x text-success mb-4"
                    ></i>
                    <h5 class="fw-bold mb-1">
                      {{
                        vehicleInfo.first_section["Rok produkcji"] +
                        " " +
                        vehicleInfo.first_section["Marka"] +
                        " " +
                        vehicleInfo.first_section["Model"]
                      }}
                    </h5>
                    <h6 class="fw-bold mb-3">{{ vehicleInfo.vin }}</h6>
                    <ul class="list-unstyled mt-3 mb-4">
                      <li v-for="(data, index) in precheckData" :key="index">
                        <span class="me-1">{{ index }}</span>
                        <i v-if="data" class="fas fa-check"></i>
                      </li>
                    </ul>
                  </div>

                </div>
                <a
                  :href="
                    'https://www.automoli.com/pl/page/vinchecker/partnerid:80000474/vin:' +
                    vehicleInfo.vin
                  "
                  target="_blank"
                  rel="noopener"
                  class="btn btn-warning text-white btn-lg w-100"
                  ><span class="h4"><strong>KUP PEŁNY RAPORT!</strong></span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InfoBlock from "./InfoBlock";

export default {
  name: "InfoBlockSection",
  components: {
    InfoBlock,
  },
  props: {
    tiles: {
      type: Object,
      default: null,
    },
    vehicleInfo: {
      type: Object,
      default: null,
    },
    precheckData: {
      type: Object,
      default: null,
    },
    additionalData: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>