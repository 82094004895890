<template>
    <div
      class="
        position-relative
        overflow-hidden
        p-3 p-md-5
        m-md-3
        text-center
        bg-grey
        rounded-3
        mb-4
      "
    >
      <div class="row">
        <div class="col-12 mb-3">
          <img
            src="../assets/img/wrong-vin-icon.png"
            width="128"
            height="128"
            alt="Vehicle found"
          />
        </div>
        <div class="col-12 mb-3">
          <h2>Brak danych dla podanego numeru VIN lub numer jest nieprawidłowy</h2>
          <h3>Wprowadź ponownie swój numer vin</h3>
        </div>
        <div class="col-12">
            <button @click="checkAgain" class="btn btn-success">Sprawdź ponownie</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { onUnmounted } from 'vue';
  
  export default {
    name: "IncorrectVin",
    components: {
    },
    setup() {
      const store = useStore();
      const router = useRouter();
  
      const checkAgain = () => {
        router.push({
          name: "home",
        });
      };
  
      onUnmounted(() => {
        return store.dispatch("RESET_STATE");
      });
  
      return {
        checkAgain,
      };
    },
  };
  </script>
  
  <style scoped>
  a {
    text-decoration: none;
    color: white;
  }
  </style>