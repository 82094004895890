<template>
  <button
    type="submit"
    class="btn btn-success btn-block btn-lg mb-0 ev-check-button"
  >
    <i class="fas fa-search me-3"></i> <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    disabledBtn: Boolean,
  }
};
</script>

<style>
</style>