import "mdb-vue-ui-kit/css/mdb.min.css";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store/store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { globalCookiesConfig } from "vue3-cookies";
import routes from "./routes/Routes";
import VueApexCharts from "vue3-apexcharts";
import VueGtag from "vue-gtag-next";
import { VueMoment } from "vue-moment";
import { createApp } from "vue";
import App from "./App.vue";

globalCookiesConfig({
  expireTimes: "30d",
  path: "/",
  domain: "http://www.easyvin.pl",
  secure: true,
  sameSite: "None",
});

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(VueGtag, {
    property: {
      id: "G-VVPR9QC5CG",
      params: {
        send_page_view: true,
        linker: {
          domain: ["easyvin.pl"],
        },
      },
    },
    useDebugger: false,
  })
  .use(VueMoment)
  .use(router)
  .use(store)
  .use(VueReCaptcha, { siteKey: "6Ld4zzMeAAAAAJhohjEHQT8EDAvwiSPL5fO3xA0B" })
  .use(VueApexCharts)
  .mount("#app");
