<template>
  <section
    id="section-timeline"
    class="ev-main-widget bg-light border rounded-5 mb-5 shadow-6-strong"
  >
    <div class="row gx-lg-2 gx-xl-4">
      <HeaderPanel
        title="Historia pojazdu"
        desc="Chronologiczna dostępna historia zdarzeń pojazdu"
      />
    </div>

    <div class="row gx-lg-2 gx-xl-4 p-4">
      <Timeline :data="timelineData" />
      <!-- TIMEline  -->
    </div>
  </section>
</template>

<script>
import HeaderPanel from "../HeaderPanel";
import Timeline from './Timeline'

export default {
  name: "TimelineSection",
  components: {
    HeaderPanel,
    Timeline,
  },
  props: {
    timelineData: Object,
  }
};
</script>

<style>
</style>