<template>
  <MDBTabs v-model="activeTabId1">
    <!-- Tabs navs -->
    <MDBTabNav tabsClasses="mb-3" id="ev-widget-tabs" role="tablist">
      <MDBTabItem
        tabId="tab-vin-content"
        class="nav-link mb-2"
        href="#tab-vin-content"
        >Sprawdź numer VIN</MDBTabItem
      >
      <MDBTabItem
        tabId="tab-plate-content"
        class="nav-link mb-2"
        href="#tab-plate-content"
        >Sprawdź Tablicę rejestracyjną</MDBTabItem
      >
    </MDBTabNav>
    <!-- Tabs navs -->
    <!-- Tabs content -->
    <MDBTabContent class="w-100">
      <MDBTabPane tabId="tab-vin-content">
        <Form :input="home().vin" rule="vin-checkbox">Numer VIN pojazdu</Form>
      </MDBTabPane>
      <MDBTabPane tabId="tab-plate-content">
         <Form :input="home().plate" rule="plate-checkbox">Numer tablicy rejestracyjnej</Form>
      </MDBTabPane>
    </MDBTabContent>
    <!-- Tabs content -->
  </MDBTabs>
</template>
<script>
import {
  MDBTabs,
  MDBTabNav,
  MDBTabContent,
  MDBTabItem,
  MDBTabPane,
} from "mdb-vue-ui-kit";
import { ref } from "vue";
import Form from "./Form";
import { InputsHelper } from '../helper/InputsHelper';
export default {
  components: {
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
    Form,
  },
  setup() {
    const activeTabId1 = ref("tab-vin-content");
    const { home } = InputsHelper();
    return {
      activeTabId1,
      home,
    };
  },
};
</script>

<style>
</style>