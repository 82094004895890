<template>
  <div class="container mt-0 mt-md-2 p-0 p-md-3">
    <div class="row">
      <div class="col my-5 text-center">
        <h1>WERYFIKACJA POJAZDU</h1>
        <img
          src="../assets/img/spin-1s-280px.svg"
          height="280"
          width="280"
          alt="Trwa sprawdzanie danych"
          loading="lazy"
        />
        <h2>Proszę czekać</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import sendReq from "@/composables/sendReq";
import getReport from "@/composables/getReport";
import checkResponse from "@/composables/checkResponse";
import { useRouter } from "vue-router";

export default {
  name: "Loader",
  setup() {
    const store = useStore();
    const router = useRouter();
    const vin = ref("");
    const plate = ref("");
    const date = ref("");
    const valid = ref(true);

    const vinNumber = computed(() => {
      return store.state.checkedVin;
    });

    const plateNumber = computed(() => {
      return store.state.checkedPlate;
    });

    onMounted(async () => {
      document.title = "Trwa sprawdzanie danych";
      store.commit("updateMessage", "empty");
      const checkedVin = vinNumber.value || null;
      const checkedPlate = plateNumber.value || null;
      const enteredVin = ref(router.currentRoute.value.params.vin ?? "");
      const enteredPlate = ref(router.currentRoute.value.params.plate ?? "");
      const enteredDate = ref(router.currentRoute.value.params.date ?? "");
      // const uuid = ref(router.currentRoute.value.params.uuid);

      const message = computed(() => {
        return store.state.message;
      });

      const uuid = computed(() => {
        return store.state.reportUuid;
      });

      const token = computed(() => {
        return store.state.token;
      });

      if (uuid.value) {
        const { req, response } = getReport();

        const getReportByUuid = await req(uuid.value);

        if (getReportByUuid) {
          if (response.value.data.message == "no_data") {
            store.commit("updateReportData", '');
            store.commit("updateReportUuid", '');
            router.push({
              name: "home",
            });
            return;
          } else if (response.value.data.status == "ok") {
            let report = [];
            report[response.value.data.reportUuid] = response.value.data.report;
            store.commit("updateReportData", report);
            store.commit("updateReportUuid", response.value.data.reportUuid);
            router.push({
              name: "report",
              params: { uuid: uuid.value },
            });
            return;
          }
        }
      }

      if (
        checkedVin === null ||
        checkedVin !== enteredVin.value ||
        checkedPlate === null ||
        checkedPlate !== enteredPlate.value ||
        (enteredVin.value && enteredPlate.value && enteredDate.value)
      ) {
        if (enteredVin.value.length > 0) {
          vin.value = enteredVin.value;
          store.dispatch("setCheckedVin", vin.value);
        } else if (enteredPlate.value.length > 0) {
          plate.value = enteredPlate.value;
          store.dispatch("setCheckedPlate", plate.value);
        }

        date.value = enteredDate.value;
        valid.value = router.currentRoute.value.params.valid;
        plate.value = enteredPlate.value ?? "";

        const { sendForm, response } = sendReq();

        const send = await sendForm(
          vin.value,
          plate.value,
          date.value,
          valid.value,
          token.value
        );

        if (send == true) {
          const { directionRouter } = checkResponse(response.value);
          const direction = directionRouter();

          if (direction.params.success) {
            router.push({
              name: direction.name,
              params: {
                uuid: direction.params.uuid,
              },
            });
          } else {
            if (message.value == "not_pass") {
              store.commit("updateMessage", "");
              router.push({
                name: "recaptcha",
              });
            } else {
              router.push({
                name: direction.name,
              });
            }
          }
        } else {
          if (send == "REQUEST_CANCELED") {
            return;
          } else {
            store.commit("updateMessage", "internal_error");
            router.push({
              name: "home",
            });
          }
        }
      }
    });

    return {
      vinNumber,
      vin,
    };
  },
};
</script>