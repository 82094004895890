import { useReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";
import axios from "axios";

export default function sendReq() {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
  const tokenV3 = ref("TOKENV3");
  const response = ref({});
  const version = ref('V2');

  const reCaptcha = async () => {
    try {
      await recaptchaLoaded();
      tokenV3.value = await executeRecaptcha("checkVin");
    } catch (error) {
      console.error(error);
    }
  };

  const sendForm = async (vin = null, plate = null, date = null, vinValidator = true, token = 'TOKEN') => {
    try {
      if (token == 'TOKEN') {
        await reCaptcha();
        token = tokenV3.value;
        version.value = 'V3';
      }

      response.value = await axios.post("/api/startUp", {
        vin: vin ?? "",
        plate: plate ?? "",
        date: date ?? "",
        token: token,
        vinValidator,
        version: version.value,
      });

      if (response.value.data.status != 'error') {
        return true;
      } else {
        return true;
      }
    } catch (error) {
      if (error.message == "REQUEST_CANCELED") {
        return error.message;
      }
      console.error(error);
    }
    return false;
  };

  const getSampleReport = async () => {
      try {
          response.value = await axios.get("/api/sampleReport");
          return true;
      } catch (error) {
          console.error(error);
      }

      return false;
  };

  return {
    sendForm,
    getSampleReport,
    response,
  };
}