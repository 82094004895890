<template>
  <section
    id="section-equipment"
    class="
      ev-main-widget
      bg-light bg-gradient
      border
      rounded-5
      mb-5
      shadow-6-strong
    "
  >
    <div class="row gx-lg-2 gx-xl-4">
      <div class="col">
        <div
          class="ev-bg-green text-white rounded-5 text-center text-md-start p-3"
        >
          <h3 class="fw-bold mb-0">Podstawowe wyposażenie pojazdu</h3>
          <p class="lead mb-0">Lista wyposażenia, które powinno znajdować się w pojeździe</p>
        </div>
      </div>
    </div>
    <div class="row gx-lg-2 gx-xl-4 p-4 pb-3">
      <div class="col">
        <dl class="row mb-0">
          <dd v-for="(equipment, index) in equipments" :key="index" class="col-md-6 col-lg-4">
            <i class="fas fa-check fa-fw text-success me-2"></i> {{ equipment.desc }}
          </dd>
        </dl>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EquipmentSection",
  props: {
    equipments: Object,
  }
};
</script>

<style>
</style>