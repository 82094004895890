<template>
  <div class="col-md-6 mb-4">
    <div class="d-flex align-items-start">
      <div class="flex-shrink-0">
        <div class="p-2 bg-white rounded-pill shadow-2-strong">
          <i :class="data.icon"></i>
        </div>
      </div>
      <div class="flex-grow-1 ms-4 text-white">
        <p class="fw-bold mb-1">{{ data.title }}</p>
        <p class="small mb-0">{{ data.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Square",
  props: {
    data: Object,
  },
};
</script>

<style>
</style>