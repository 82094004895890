<template>
  <div class="d-flex justify-content-center">
    <div class="">
      <h2 class="fw-bold my-4">Co to jest VIN?</h2>
      <p class="text-muted mb-5">
        <strong>Numer VIN</strong> to numer nadwozia pojazdu. Najczęściej
        umieszczony jest w taki sposób, aby nie było możliwe usunięcie,
        zniszczenie lub "przebicie". Numer VIN znajdziemy najczęściej w
        samochodach w komrze silnika lub kabinie pasażerskiej, w motocyklach na
        główce ramy, przy kierownicy. W dowodzie rejestracyjnym znajdziesz go w
        pozycji oznaczonej literą "E". VIN to "DNA" pojazdu zakodowane przez
        producenta. VIN kryje w sobie charakterystyczne dla danego modelu
        pojazdu informacje, takie jak rok produkcji, model i marka, typ silnika,
        miejsce montażu oraz sumę kontrolną, dzięki której można dowiedzieć się,
        czy numer nadwozia nie został przebity.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatIsVin",
};
</script>

<style>
</style>