<template>
  <div class="container my-2">
    <!-- Section: Design Block -->
    <section>
      <div class="row mb-5">
        <div class="col-12">
          <h1 class="fw-bold my-4">Polityka prywatności serwisu internetowego EasyVIN</h1>
          <p>
            Chronimy prywatność każdego użytkownika naszej strony internetowej prowadzonej pod nazwą "EasyVIN”, w domenie
            internetowej "www.easyvin.pl" (dalej zwanej "Stroną").
          </p>
          <p>
            Administratorem Twoich danych jest Autoiso sp. z o.o. z siedzibą pod adresem: ul. Gnieźnieńska 12, 40-142
            Katowice (Polska). W razie jakichkolwiek pytań dotyczących naszej Polityki prywatności lub przetwarzania przez
            nas danych osobowych, a także chęci złożenia dyspozycji co do swoich danych, prosimy o kontakt pod adresem
            e-mail: pomoc@autoiso.pl lub pod podanym wyżej adresem siedziby.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">
            I. Przetwarzane dane osobowe, cele, podstawy prawne i okresy przetwarzania
          </h3>
          <p>
            Możemy zbierać, przechowywać i przetwarzać następujące rodzaje danych:
          </p>
          <ol>
            <li class="mb-3">
              informacje o komputerze użytkownika Strony, jego wizytach na Stronie i korzystaniu z niej (w szczególności:
              adres IP, informacje o przeglądarce i systemie operacyjnym, przybliżona lokalizacja, źródło odesłania do
              Strony i historia korzystania z niej), które przetwarzamy na podstawie naszych prawnie uzasadnionych
              interesów w celu zapewnienia poprawnego działania strony, przez okres do zakończenia tych działań albo do
              zgłoszenia przez użytkownika skutecznego sprzeciwu, oraz na podstawie Twojej zgody w celu usprawnienia
              korzystania z niej przez zapamiętanie ustawień wybranych przez użytkownika (takich jak niektóre elementy
              wyglądu strony, dane logowania), a także zmieniania strony i prowadzenia działań marketingowych na podstawie
              analizy korzystania z niej przez użytkowników, przez okres do wycofania zgody. Zgodę możesz cofnąć w
              dowolnym momencie wybierając taką opcję w ustawieniach Strony, jednak pozostanie to bez wpływu na zgodność z
              prawem przetwarzania dokonanego na podstawie zgody przed jej cofnięciem. Udostępnianie większości z tych
              danych odbywa się za pomocą plików cookies lub podobnych technologii. Nie jest ono konieczne, poza danymi
              niezbędnymi do poprawnego funkcjonowania Strony, więc można zmieniać zakres udostępniania danych wybierając
              odpowiednie ustawienia Cookies w przeglądarce internetowej lub w ustawieniach na Stronie, choć w
              konsekwencji pewne funkcjonalności Strony mogą nie działać poprawnie;
            </li>
            <li class="mb-3">
              informacje niezbędne do świadczenia usług dostępnych na Stronie (w szczególności: adres e-mail, hasło, imię
              i nazwisko, nazwa, adres, NIP, nr rachunku bankowego, nr telefonu, historia korzystania z naszych usług),
              które przetwarzamy na podstawie: <br>
              <ol type="a">
                <li>umów opisanych w regulaminie Strony i regulaminach współpracy biznesowej dostępnych na Stronie, w celu
                  zawarcia, wykonywania i rozwiązania tych umów, przez okres ich zawierania i obowiązywania,
                </li>
                <li>
                  naszych prawnie uzasadnionych interesów w celu zawarcia wymienionych wyżej umów, ochrony przed
                  roszczeniami i realizacji roszczeń z nimi związanych, marketingu bezpośredniego, analizy korzystania z
                  naszych usług, przez okres do 6 lat od roku, w którym wykonano lub rozwiązano umowę, albo do zgłoszenia
                  przez użytkownika skutecznego sprzeciwu, lecz nie krócej niż do zakończenia realizacji roszczeń
                  związanych z umową i upłynięcia okresu przedawnienia,
                </li>
                <li>
                  ciążących na nas obowiązków prawnych wynikających z ustawy o rachunkowości (art. 4 ust. 2 i art.
                  20-21, 71 ust. 1), Ordynacji podatkowej (art. 88 ust. 1) i ustawy o podatku VAT (art. 112-112a) w celu
                  udokumentowania transakcji i rozliczenia podatków, przez okresy wskazane w tych ustawach (obecnie wg
                  art. 74 ust. 2 ustawy o rachunkowości, art. 112 ustawy o VAT i art. 70 § 1 Ordynacji podatkowej jest to
                  okres 5 lat od początku roku następującego po roku obrotowym, którego dane dotyczą). Udostępnianie tych
                  danych jest wymogiem umownym (pkt. a i b) lub ustawowym (pkt c) i jest konieczne w przypadku korzystania
                  z usług świadczonych na Stronie;
                </li>
              </ol>
            </li>
            <li class="mb-3">
              informacje związane z subskrybowaniem usług informacyjnych na Stronie (adres e-mail), w szczególności w celu
              przesyłania Ci pocztą elektroniczną newslettera z informacjami związanymi z treścią tej strony internetowej,
              w tym materiałami marketingowymi, na podstawie zgody użytkownika, przez okres funkcjonowania tych usług albo
              do wycofania zgody. Udostępnianie tych danych nie jest wymogiem ustawowym lub umownym, ale jest konieczne do
              korzystania z subskrypcji;
            </li>
            <li class="mb-3">
              informacje niezbędne do komunikacji (w szczególności adres e-mail, nr telefonu, imię i nazwisko, dane z portali społecznościowych oraz treść korespondencji), które przetwarzamy na podstawie naszych prawnie uzasadnionych interesów w celu komunikacji z użytkownikami, dla ochrony przed roszczeniami i realizacji roszczeń, przez okres do 6 lat od roku, w którym zakończono komunikację, albo do zgłoszenia przez użytkownika skutecznego sprzeciwu. Udostępnianie tych danych nie jest wymogiem ustawowym lub umownym, ale jest konieczne do komunikacji.
            </li>
          </ol>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">II. Pliki cookies (ciasteczka)</h3>
          <p>
            Strona używa plików zwanych ciasteczkami (cookies) lub innych podobnych technologii, zwanych łącznie w tym dokumencie „Cookies”. Cookies zawierają informacje wysyłane przez serwer sieciowy do przeglądarki internetowej użytkownika, które są zapisywane w tej przeglądarce. Następnie informacje te są przesyłane z powrotem do serwera za każdym razem, kiedy przeglądarka otwiera stronę internetową z tego serwera. To pozwala serwerowi na zidentyfikowanie i śledzenie przeglądarki użytkownika rejestrując różne rodzaje jego aktywności na tej stronie, a następnie analizowanie tak uzyskanych danych.
          </p>
          <!-- <p> -->
            Strona może używać w szczególności Cookies: <br>
            <ol type="O" class="dash-list mt-0 pt-0">
              <li>
                niezbędnych do umożliwienia korzystania z podstawowych funkcji Strony i jej prawidłowego funkcjonowania;
              </li>
              <li>
                umożliwiających zachowanie preferencji użytkownika zależnych od niego i urządzenia, dzięki któremu korzysta ze Strony, wpływających na jej wygląd lub funkcjonowanie, np. w zakresie języka lub przybliżonej lokalizacji, a także zachowanie danych logowania użytkownika;
              </li>
              <li>
                umożliwiających prowadzenie i analizowanie statystyk używania Strony przez użytkowników, aby wprowadzać na tej podstawie ulepszenia i inne zmiany na Stronie;
              </li>
              <li>
                umożliwiających prowadzenie działań marketingowych, w tym do wyświetlania reklam z wykorzystaniem profilowania użytkowników (zob. punkt III Polityki prywatności).
              </li>
            </ol>
          <!-- </p> -->
          <p>
            Strona używa Cookies sesji do śledzenia użytkowników korzystających ze Strony, natomiast Cookies trwałych do rozpoznawania użytkowników odwiedzających Stronę. Cookies sesji są usuwane z komputera użytkownika, kiedy jego przeglądarka jest zamykana. Cookies trwałe pozostają na komputerze użytkownika do chwili ich usunięcia lub wygaśnięcia ich daty ważności.
          </p>
          <p>
            Osoby trzecie, w szczególności nasi reklamodawcy lub współpracujące z nami serwisy płatnicze, mogą również używać Cookies w stosunku do użytkowników Strony na zasadach określonych na ich stronach internetowych. W szczególności, w celu analizowania jak korzystają ze Strony użytkownicy stosujemy Google Analytics, usługę świadczoną przez Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland), która przy pomocy Cookies zapisywanych na komputerach użytkowników pozwala na zbieranie i analizowanie informacji statystycznych oraz innych o korzystaniu ze Strony w celu uzyskania wiedzy, jak Strona jest używana, w tym badania skuteczności marketingu i wyświetlania użytkownikom dostosowanych do nich reklam (polityka prywatności Google jest dostępna pod adresem: http://www.google.com/privacypolicy.html).
          </p>
          <p>
            Używanie Cookies wymaga wyrażenia przez Ciebie zgody, chyba że są to Cookies konieczne do dostarczenia usługi świadczonej przez nas za pośrednictwem Strony na Twoje żądanie. Zgoda na używanie Cookies z naszej Strony wyrażana jest za pośrednictwem ustawień wybranych przez Ciebie w przeglądarce internetowej. Większość przeglądarek pozwala na wybranie opcji odrzucania wszelkich Cookies lub Cookies osób trzecich. Na przykład w przeglądarce Chrome można wybrać opcję odrzucania wszelkich Cookies wybierając w „Ustawieniach” sekcję „Prywatność i bezpieczeństwo”, a następnie „Pliki cookie” i tam klikając w przełącznik przy pozycji „Zablokowane”. Jednakże odrzucanie wszelkich Cookies może utrudniać lub nawet uniemożliwiać korzystanie ze stron internetowych, w szczególności w przypadku logowania się na tych stronach. Dodatkowo prosimy Cię o potwierdzenie wyrażenia zgody na używanie Cookies, kiedy odwiedzasz Stronę. Możesz w dowolnym momencie cofnąć udzieloną zgodę zmieniając ustawienia przeglądarki lub ustawienia zgód dostępne na Stronie albo informując nas o tym przez e-mail, co jednak pozostanie bez wpływu na zgodność z prawem przetwarzania dokonanego na podstawie zgody przed jej cofnięciem.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">III. Zautomatyzowane podejmowanie decyzji</h3>
          <p>
            Przetwarzane przez nas Twoje dane mogą być wykorzystywane przez nas lub naszych partnerów (odbiorców danych) do stosowania zautomatyzowanego podejmowania decyzji, w tym z profilowaniem, tj. z wykorzystaniem danych osobowych do oceny czynników osobowych osoby fizycznej takich jak jej preferencje lub zainteresowania, w szczególności w celu reklamowania naszych usług na Stronie lub innych stronach internetowych (nasi partnerzy świadczący dla nas usługi reklamowe korzystając z Cookies mogą automatycznie tworzyć tzw. profil Twoich preferencji i wyświetlać dla Ciebie na stronach internetowych reklamy naszych usług, ponieważ kiedyś z tych usług skorzystałeś lub odwiedziłeś naszą Stronę). Dla prowadzenia takich działań nie jest potrzebna Twoja zgoda, ponieważ nie wywołują one wobec Ciebie skutków prawnych ani istotnie na Ciebie nie wpływają w inny podobny sposób, jednak profilowanie odbywa się za pomocą Cookies, które są stosowane po uzyskaniu Twojej zgody (zob. punkt II Polityki prywatności).
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">IV. Odbiorcy danych</h3>
          <p>
            Nasi pracownicy i współpracownicy mogą mieć dostęp do danych użytkowników, jeśli jest to uzasadnione dla realizacji celów określonych w niniejszej Polityce prywatności, przy czym są oni zobowiązani do należytej ochrony tych danych.
          </p>
          <p>
            Możemy ujawniać Twoje dane podmiotom, z którymi współpracujemy i które przetwarzają te dane w naszym imieniu (odbiorcy danych) w celu świadczenia dla nas usług z zakresu: hostingu, informatyki, statystyki i analityki, marketingu, oprogramowania do zarządzania firmą i bazą kontaktów oraz komunikacji elektronicznej, płatności internetowych, bankowości, księgowości i doradztwa prawnego, zbierania i udostępniania danych o pojazdach, wyceny pojazdów. Podmioty te mogą łączyć ujawnione im informacje z innymi danymi otrzymanymi od Ciebie lub uzyskanymi podczas korzystania z ich usług.
          </p>
          <p>
            Dane mogą być przekazywane poza Europejski Obszar Gospodarczy, ale jedynie do państw lub podmiotów zapewniających stopień ochrony danych osobowych odpowiadający regulacjom UE, w szczególności wobec których Komisja Europejska wydała odpowiednią decyzję lub które stosują tzw. klauzule standardowe określone przez Komisję Europejską w decyzji wykonawczej nr 2021/914 z 4 czerwca 2021 r.
          </p>
          <p>
            Ponadto możemy przekazywać podmiotom publicznym dane dotyczące użytkowników tylko w takim zakresie, w jakim wymaga tego od nas obowiązujące prawo.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">V. Uprawnienia użytkowników</h3>
          <p>
            Jako użytkownik Strony masz prawo dostępu do dotyczących Ciebie danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz do wniesienia sprzeciwu wobec ich przetwarzania, a także do przenoszenia tych danych. W tym celu skontaktuj się z nami za pośrednictwem formularza kontaktowego lub przez e-mail.
          </p>
          <p>
            Jako użytkownik Strony masz prawo do złożenia skargi na nasze działania w zakresie przetwarzania Twoich danych osobowych do Prezesa Urzędu Ochrony Danych Osobowych w Polsce lub jego odpowiednika w innym państwie UE, według miejsca Twojego zwykłego pobytu, pracy lub miejsca popełnienia domniemanego naruszenia. Będziemy wdzięczni, jeśli przed złożeniem skargi przekażesz nam swoje zastrzeżenia do naszych działań, a przez to dasz szansę na podjęcie przez nas próby samodzielnego wyjaśnienia zgłoszonej sprawy.
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">VI. Bezpieczeństwo danych</h3>
          <p>
            Stosujemy odpowiednie zabezpieczenia techniczne i organizacyjne, aby zapobiec utracie, niewłaściwemu wykorzystaniu lub przerobieniu danych użytkowników.
          </p>
          <p>
            Przechowujemy przekazywane nam przez użytkowników dane na naszych bezpiecznych serwerach (chronionych hasłami i firewallami). Przekazywanie poufnych danych oraz wszystkie transakcje elektroniczne odbywają się za pośrednictwem technologii SSL.
          </p>
          <p>
            Podkreślamy, że transmisja danych w Internecie jest z zasady narażona na niebezpieczeństwa. Jednakże stosujemy różnego rodzaju środki, w celu zabezpieczenia danych osobowych. Jeśli będziesz chciał nam przekazać sugestie co do stosowania takich zabezpieczeń, zachęcamy do kontaktu przez formularz kontaktowy lub przesłanie nam wiadomości e-mail.
          </p>
          <p>
            Zalecamy użytkownikom zachowanie w poufności loginów i haseł do Strony ze względów bezpieczeństwa. Nigdy nie prosimy użytkowników o przekazanie nam ich haseł (za wyjątkiem logowania się na Stronie).
          </p>
        </div>

        <div class="col-12">
          <h3 class="fw-bold my-4">VII. Strony osób trzecich</h3>
          <p>
            Strona zawiera linki do stron osób trzecich. Za politykę prywatności i praktykę w zakresie przetwarzania danych osobowych na tych Stronach odpowiadają podmioty prowadzące je.
          </p>
        </div>
      </div>
    </section>
    <!-- Section: Design Block -->
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
export default {
  name: "Poc",
  setup() {
    onMounted(() => {
      document.title = 'Polityka prywatności i Cookies';
    });
  }
}
</script>

<style scoped>
.dash-list {
  list-style-type: none;
}

.dash-list li::before {
  content: "-";
}

</style>