<template>
  <footer class="ev-bg-footer text-center text-lg-start">
    <div class="text-center text-white p-3">
      <ul class="list-inline small">
        <Link v-for="(data, index) in linksData" :cookies="index == 'cookies' || ''" :key="index" :data="data" />
      </ul>
      <p class="mb-0">
        &copy;{{ new Date().getFullYear() }} easyVIN.pl. Wszelkie prawa
        zastrzeżone.
      </p>
    </div>
  </footer>
</template>

<script>
import Link from "./Link";

export default {
  name: "Footer",
  components: {
    Link,
  },
  setup() {
    const linksData = {
      regulations: {
        href: "/regulamin-serwisu",
        title: "Regulamin serwisu",
        child: "Regulamin serwisu",
      },
      policy: {
        href: "/polityka-prywatnosci",
        title: "Polityka prywatności",
        child: "Polityka prywatności",
      },
    };

    return {
      linksData,
    };
  },
};
</script>

<style>
</style>