export default function regValidation(plate, $countryCode = 'PL') {
    //  Funkcja sprawdzająca, czy tablica odpowiada polskiemu formatowi
    //  obsługuje samochodowe tablice rejestracyjne zwyczajne oraz zmniejszone (4-znakowe)

    if($countryCode != 'PL') return false;

    const regPlate = plate.trim().toUpperCase();
    const availableLetters = /^[ABCDEFGHIJKLMNOPRSTUVWXYZ0123456789]+$/i; // mozliwe znaki w tablicy
    const vehicleIdentifyNotAvailableLetters = /[BDIOZ]/i; // znaki niemozliwe w czesci wyróżnika pojazdu
    const firstLetterAvailable = /^[BCDEFGKLNOPRSTWZ]+$/i; // mozliwe pierwsze litery w wyróżniku województwa

    try {
        if(!regPlate.match(availableLetters)) {
            throw new Error('tablica zawiera znaki inne niz okreslone w availableLetters');
        }

        // const matches = regPlate.match(availableLetters);
        let matches;
    
        if(regPlate.length == 4) {
            // zmniejszona tablica
            matches = regPlate.match(/^[A-Z]/);
        } else {
            matches = regPlate.match(/^[A-Z]{2,3}/);
        }

        const regionPart = matches[0] ?? null; // wyróżnik województwa
        const vehicleIdentifyPart = regPlate.substr(regionPart.length);

        if(regionPart.length < 1) {
            // nie ma wyróżnika województwa
            return false;
        }
    
        if(!regionPart[0].match(firstLetterAvailable)) {
            console.log('regionPart[0]', regionPart);
            // pierwsza litera wyróżnika województwa jest inna niz mozliwa w firstLetterAvailable
            throw new Error('Tablica nie przeszla walidacji (niedozwolona pierwsza litera): ' + regPlate);
        }
    
        if(vehicleIdentifyPart.match(vehicleIdentifyNotAvailableLetters)) {
            // wyróżnik pojazdu zawiera znaki niedozwolone okreslone w vehicleIdentifyNotAvailableLetters
            throw new Error('Tablica nie przeszla walidacji (niedozwolone znaki): ' + regPlate);
        }
    
        let possibleCombinationsRegexes = [];

        if(regionPart.length == 1) { // tablica zmniejszona
            possibleCombinationsRegexes = [
                /^(?!000)[0-9]{1,3}$/, // trzy cyfry w przedziale od 001 do 999
                /^(?!00)[0-9]{2}[A-Z]$/, // dwie cyfry w przedziale od 01 do 99 i litera
                /^[1-9][A-Z][1-9]$/, // cyfra w przedziale od 1 do 9, litera i cyfra w przedziale od 1 do 9
                /^[A-Z](?!00)[0-9]{2}$/, // litera i dwie cyfry w przedziale od 01 do 99
                /^[1-9][A-Z]{2}$/, // cyfra w przedziale od 1 do 9 i dwie litery
                /^[A-Z]{2}[1-9]$/, // dwie litery i cyfra w przedziale od 1 do 9
                /^[A-Z][1-9][A-Z]$/ // litera, cyfra w przedziale od 1 do 9 i litera
            ];
        } else if(regionPart.length == 2) { // powiat jednoliterowy np. SL
            possibleCombinationsRegexes = [
                /^(?!00000)[0-9]{5}$/, // pięć cyfr w przedziale od 00001 do 99999
                /^(?!0000)[0-9]{4}[A-Z]$/i, // cztery cyfry w przedziale od 0001 do 9999 i litera
                /^(?!000)[0-9]{3}[A-Z]{2}$/i, // trzy cyfry w przedziale od 001 do 999 i dwie litery
                /^[1-9][A-Z](?!000)[0-9]{3}$/i, // cyfra w przedziale od 1 do 9, litera i trzy cyfry w przedziale od 001 do 999
                /^[1-9][A-Z]{2}(?!00)[0-9]{2}$/i // cyfra w przedziale od 1 do 9, dwie litery i dwie cyfry w przedziale od 01 do 99
            ];
        } else if(regionPart.length == 3) { // powiat dwuliterowy np. SGL
            possibleCombinationsRegexes = [
                /^[A-Z](?!000)[0-9]{3}$/, // litera i trzy cyfry w przedziale od 001 do 999
                /^(?!00)[0-9]{2}[A-Z]{2}$/i, // dwie cyfry w przedziale od 01 do 99 i dwie litery
                /^[1-9][A-Z](?!00)[0-9]{2}$/i, // cyfra w przedziale od 1 do 9, litera i dwie cyfry w przedziale od 01 do 99
                /^(?!00)[0-9]{2}[A-Z][1-9]$/i, // dwie cyfry w przedziale od 01 do 99, litera i cyfra w przedziale od 1 do 9
                /^[1-9][A-Z]{2}[1-9]$/i, // cyfra w przedziale od 1 do 9, dwie litery i cyfra w przedziale od 1 do 9
                /^[A-Z]{2}(?!00)[0-9]{2}$/i, // dwie litery i dwie cyfry w przedziale od 01 do 99
                /^(?!00000)[0-9]{5}$/, // pięć cyfr w przedziale od 00001 do 99999
                /^(?!0000)[0-9]{4}[A-Z]$/i, // cztery cyfry w przedziale od 0001 do 9999 i litera
                /^(?!000)[0-9]{3}[A-Z]{2}$/i // trzy cyfry w przedziale od 001 do 999 i dwie litery
            ];
        }

        let validate = false;
        possibleCombinationsRegexes.forEach(regex => {
            if (vehicleIdentifyPart.match(regex)) {
                validate = true;
            }
        });

        return validate;
    } catch (error) {
        console.error(error);
        // wyróżnik pojazdu nie przeszedl walidacji z mozliwych kombinacji cyfr i znakow
        return false;   
    }
}