export const defaultState = () => {
  return {
    vinValidator: {
      vin_alternatives_list: [],
      vin_correct: "",
      vin_orginal: "",
    },
    precheck: {
      odometers: 0,
      damages: 0,
      events: 0,
      photos: 0,
    },
    reportData: {},
    decoderData: {},
    stolen: false,
    message: "empty",
    showCookie: true,
    checkedVin: "",
    checkedPlate: "",
    // cancelTokens: [],
    precheckStatus: false,
    lastCheckedVins: [],
    isRobot: true,
    token: 'TOKEN',
    reportUuid: '',
    fillData: {
      vin: "",
      plate: "",
    },
  };
};
