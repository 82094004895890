<template>
  <div v-for="(singleData, index) in data" :key="index" class="ev-tl-item col-12 my-2">
    <div
      class="
        p-2
        bg-gradient
        rounded-4
        shadow-2-strong
        d-inline-block
        mb-4
        text-white
      "
      :class="singleData.backgroundColor"
    >
      <i :class="singleData.icon"></i>
      <strong>{{ singleData.date }}</strong>
    </div>
    <div
    v-if="singleData.odometer.length"
      class="
        p-2
        bg-dark bg-gradient
        rounded-4
        shadow-2-strong
        d-inline-block
        mb-4
        text-white
        float-end
      "
    >
      <strong>{{ singleData.odometer }}</strong>
    </div>
    <div
      class="pb-3 px-3 px-sm-4 rounded-5 shadow-2-strong ev-timeline bg-light"
    >
      <h5 class="fw-bold mb-2">{{ singleData.title }}</h5>
      <p v-for="(element, key) in singleData.desc" :key="key" v-html="element.result" class="m-0"></p>
    </div>
  </div>
  <!-- || -->
  <!-- <div class="ev-tl-item col-12 my-2">
    <div
      class="
        p-2
        bg-danger bg-gradient
        rounded-4
        shadow-2-strong
        d-inline-block
        mb-4
        text-white
      "
    >
      <i class="fas fa-car-crash text-white fa-fw me-2"></i>
      <strong>2015-02-19</strong>
    </div>
    <div
      class="
        pb-3
        px-3 px-sm-4
        border border-danger
        rounded-5
        shadow-2-strong
        ev-timeline
        bg-light
      "
    >
      <h5 class="fw-bold mb-2">Powstanie szkody istotnej</h5>
      <p class="m-0">
        Kategorie:
        <span class="text-danger"
          ><strong>Uszkodzenie elementów układu kierowniczego</strong></span
        >
      </p>
      <p class="m-0">Ubezpieczyciel: <strong>Zakład Ubezpieczeń</strong></p>
    </div>
  </div> -->

  <!-- ||  -->

  <!-- <div class="ev-tl-item col-12 my-2">
    <div
      class="
        p-2
        bg-success bg-gradient
        rounded-4
        shadow-2-strong
        d-inline-block
        mb-4
        text-white
      "
    >
      <i class="fas fa-car text-white fa-fw me-2"></i>
      <strong>2016-01-20</strong>
    </div>
    <div
      class="
        p-2
        bg-dark bg-gradient
        rounded-4
        shadow-2-strong
        d-inline-block
        mb-4
        text-white
        float-end
      "
    >
      <strong>12.225 km</strong>
    </div>
    <div
      class="pb-3 px-3 px-sm-4 rounded-5 shadow-2-strong ev-timeline bg-light"
    >
      <h5 class="fw-bold mb-2">Dodatkowe badanie techniczne</h5>
      <p class="m-0">
        Wynika badania:
        <span class="text-success"><strong>pozytywny</strong></span>
      </p>
      <p class="m-0">
        Rodzaj badania:
        <strong
          >Uczestniczył w wypadku lub narusza wym. ochr. środowiska</strong
        >
      </p>
      <p class="m-0">
        Błąd odczytu drogomierza:
        <strong>Wartość drogomierza nieczytelna lub niekompletna</strong>
      </p>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "Timeline",
  props: {
    data: Object,
  }
};
</script>

<style>
</style>