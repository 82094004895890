<template>
  <div class="col">
    <div class="ev-bg-green text-white rounded-5 text-center text-md-start p-3">
      <h3 class="fw-bold mb-0">{{ title }}</h3>
      <p class="lead mb-0">{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPanel",
  functional: true,
  props: {
    title: String,
    desc: String,
  },
};
</script>

<style>
</style>