<template>
  <section
    id="section-stolen"
    class="ev-main-widget bg-light border rounded-5 mb-5 shadow-6-strong"
  >
    <div class="row gx-lg-2 gx-xl-4">
      <HeaderPanel
        title="Informacja kradzieżowa"
        desc="Sprawdzenie pojazdu w bazach pojazdów kradzionych dostępnych online"
      />
    </div>
    <div class="row gx-lg-2 gx-xl-4 p-4">
      <div class="col-md-4 col align-self-center text-center">
        <i
          v-if="stolenData.stolen_count > 0"
          class="fas fa-exclamation-triangle fa-6x text-danger mb-4 mb-md-0"
        ></i>
        <!-- LUB -->
        <i v-else class="fas fa-check fa-6x text-success mb-4 mb-md-0"></i>
      </div>
      <div class="col-md-8">
        <div class="ev-label-item">
          <span>Wynik weryfikacji:</span>
          <p> {{ stolenData.stolen_count > 0 ? 'Pojazd oznaczony w ewidencji jako kradziony!' : 'Pojazd oznaczony w ewidencji jako niekradziony!' }}</p>
        </div>
        <p class="fst-italic m-0">
          <strong>Uwaga!</strong> Najpewniejszym źródłem informacji o kradzionych pojazdach jest policja lokalna w danym kraju. Często pojazd, który został zgłoszony jako skradziony lub zaginiony nie zostaje wykreślony z rejestrów po jego odnalezieniu. Właściciel po odnalezieniu pojazdu nie pamięta wszystkich miejsc, gdzie zgłosił taki pojazd jako poszukiwany. Ze względu na ograniczenia prawne nie mamy możliwości sprawdzenia we wszystkich policyjnych rejestrach więc nie można wykluczyć, że pojazd który nie figuruje w żadnej z dostępnych baz nie jest faktycznie poszukiwany.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderPanel from "../HeaderPanel";

export default {
  name: "StolenSection",
  components: {
    HeaderPanel,
  },
  props: {
    stolenData: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>