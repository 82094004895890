import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist';
import { defaultState } from "../utils/config";

export default createStore({
  state() {
    return defaultState();
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
    lastCheckedVins(state) {
      return state.lastCheckedVins;
    },
    precheckStatus(state) {
      return state.precheckStatus;
    },
    reportData(state) {
      return state.reportData;
    },
    message(state) {
      return state.message;
    }
  },
  mutations: {
    updateVinValidator(state, payload) {
      const res = payload.result;
      state.vinValidator = {
        vin_alternatives_list: res.vin_alternatives_list,
        vin_correct: res.vin_correct,
        vin_orginal: res.vin_orginal,
      };
    },
    updateMessage(state, payload) {
      state.message = payload;
    },
    updateStolen(state, payload) {
      state.stolen = payload;
    },
    updateShowCookie(state, payload) {
      state.showCookie = payload;
    },
    updateCheckedVin(state, payload) {
      state.checkedVin = payload;
    },
    updateCheckedPlate(state, payload) {
      state.checkedPlate = payload;
    },
    updatePrecheckData(state, payload) {
      state.precheck = {
        odometers: payload.odometers,
        damages: payload.damages,
        events: payload.events,
        photos: payload.photos,
      };
    },
    updateReportData(state, payload) {
      const reportData = state.reportData;
      state.reportData = {
        ...reportData,
        ...payload,
      };
    },
    updateDecoderData(state, payload) {
      state.decoderData = payload;
    },
    updatePrecheckStatus(state, payload) {
      state.precheckStatus = payload;
    },
    updateLang(state, payload) {
      state.lang = payload;
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
    resetData(state) {
      for (const singleData in state) {
        if (singleData == "lastCheckedVins" || singleData == 'token' || singleData == 'reportData') {
          continue;
        }
        state[singleData] = defaultState()[singleData];
      }
    },
    updateLastCheckedVins(state, payload) {
      state.lastCheckedVins = payload;
    },
    updateIsRobot(state, payload) {
      state.isRobot = payload;
    },
    updateToken(state, payload) {
      state.token = payload;
    },
    updateReportUuid(state, payload) {
      state.reportUuid = payload;
    },
    updateFillData(state, payload) {
      state.fillData = payload;
    }
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) {
          request.cancel("REQUEST_CANCELED");
        }
      });

      context.commit("CLEAR_CANCEL_TOKENS");
    },
    async RESET_STATE(context) {
      context.commit("resetData");
    },
    setCheckedVin({ commit }, payload) {
      commit("updateCheckedVin", payload);
    },
    setCheckedPlate({ commit }, payload) {
      commit("updateCheckedPlate", payload);
    },
    addLastCheckedVins({ commit }, payload) {
      commit("updateLastCheckedVins", payload);
    },
    setPrecheckStatus({ commit }, payload) {
      commit("updatePrecheckStatus", payload);
    },
  },
  plugins: [new VuexPersistence().plugin],
});
