export const InputsHelper = () => {
  const home = () => {
    return {
      vin: {
        div: false,
        type: "text",
        id: "vin",
        placeholder: "zfa1111111111",
        classess:
          "form-control form-control-lg text-center text-uppercase bg-white ev-vin-input",
        maxlength: "17",
      },
      plate: {
        div: true,
        type: "text",
        id: "plate",
        placeholder: "Wpisz tablicę rej.",
        classess:
          "form-control form-control-lg text-center text-uppercase bg-white ev-plate-input-reg",
        maxlength: "10",
      },
    };
  };

  const fill = () => {
    return {
      vin: {
        div: false,
        type: "text",
        id: "vin",
        placeholder: "Wpisz numer VIN",
        classess:
          "form-control form-control-lg text-center text-uppercase bg-white ev-vin-input",
        maxlength: "17",
      },
      plate: {
        div: true,
        type: "text",
        id: "plate",
        placeholder: "Wpisz tablicę rej.",
        classess:
          "form-control form-control-lg text-center text-uppercase bg-white ev-plate-input-reg",
        maxlength: "10",
      },
      firstReg: {
        div: false,
        type: "text",
        id: "firstreg",
        placeholder: "Wprowadź datę rejestracji",
        classess:
          "form-control form-control-lg text-center text-uppercase bg-white ev-date-input",
        maxlength: "15",
      },
    };
  };

  return {
    home,
    fill,
  }
};
