<template>
  <div>
    <apexchart
      id="vuechart-odometer"
      type="area"
      :options="chartOptions"
      :series="series"
      width="100%"
      height="350"
    ></apexchart>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity';
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
      data: {
          type: Object,
          default: null,
      }
  },
  setup(props) {
    const { data } = toRefs(props);
    const dates = Object.keys(data.value);
    const odometers = Object.values(data.value);
    const odometersData = ref([]);
    
    for (const odometer of odometers) {
        odometersData.value.push(odometer.replace(' km', ''));
    }

    const chartOptions = {
      chart: {
        id: "vuechart-odometer",
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      markers: {
        size: 5,
        colors: ["#39c0ed"],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: dates,
        labels: {
          rotate: -15,
          rotateAlways: true,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + " km";
          },
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd",
        },
      },
    };

    const series = [
      {
        name: "Odczyt drogomierza",
        data: odometersData.value,
      },
    ];

    return {
      chartOptions,
      series,
    };
  },
};
</script>