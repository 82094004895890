<template>
  <li class="list-inline-item mb-3 mx-4">
    <router-link
      v-if="cookies"
      @click="showCookies"
      class="text-white"
      :title="data.title"
      aria-current="page"
      :to="data.href"
      >{{ data.child }}</router-link
    >

    <router-link
      v-else
      class="text-white"
      :title="data.title"
      aria-current="page"
      :to="data.href"
      >{{ data.child }}</router-link
    >
  </li>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Link",
  props: {
    data: Object,
    cookies: String,
  },
  setup() {
    const store = useStore();

    const showCookies = () => {
      return store.commit("updateShowCookie", true);
    };

    return {
      showCookies,
    };
  },
};
</script>

<style>
</style>