<template>
  <div class="container mt-0 mt-md-2 p-0 p-md-3">
    <div class="row">
      <div class="col my-5 text-center">
        <h1>WERYFIKACJA UŻYTKOWNIKA</h1>
        <p>Aby przejść dalej wypełnij poniższe zadanie</p>
        <vue-recaptcha
          sitekey="6Lc8TvMZAAAAAJJWO8h_TtYMs-FJ9yuzy0oqUdOd"
          size="normal"
          theme="light"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
          class="d-flex justify-content-center"
        >
        </vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import vueRecaptcha from "vue3-recaptcha2";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted } from '@vue/runtime-core';

export default {
  name: "Recaptcha",
  components: {
    vueRecaptcha,
  },
  setup() {
    const store = useStore();
    const token = ref("TOKEN");
    const router = useRouter();

    const checkedVin = computed(() => {
      return store.state.checkedVin;
    });

    //captcha start
    const recaptchaVerified = (response) => {
      token.value = response;
      store.commit("updateIsRobot", false);
      store.commit("updateToken", token.value);

      router.push({
        name: "loader",
        params: {
          vin: checkedVin.value,
        },
      });
    };

    const recaptchaExpired = () => {
      store.commit("updateIsRobot", true);
      this.$refs.vueRecaptcha.reset();
    };

    const recaptchaFailed = (response) => {
      console.log("failed", response);
    };

    onMounted(() => {
      document.title = 'Weryfikacja użytkownika'
    });

    //captcha end

    return {
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
    };
  },
};
</script>