<template>
  <div class="row gx-lg-2 gx-xl-4 px-4 pt-4 pb-2">
    <!-- first section -->
    <div class="col-md-4">
      <div
        v-for="(data, name) in decoderData.first_section"
        :key="name"
        class="ev-label-item"
      >
        <span v-if="data.value || (data && typeof data !== 'object')">{{ name }}:</span>
        <p v-if="data.value || (data && typeof data !== 'object')">{{ data.value ?? data }}</p>
      </div>
    </div>

    <div class="col-md-4">
      <div
        v-for="(data, name) in decoderData.second_section"
        :key="name"
        class="ev-label-item"
      >
        <span v-if="data.value || (data && typeof data !== 'object')">{{ name }}:</span>
        <p v-if="data.value || (data && typeof data !== 'object')">{{ (data.value ?? data) + ' ' + (data.unit ?? '') }}</p>
      </div>
    </div>

    <div class="col-md-4">
      <div
        v-for="(data, name) in decoderData.third_section"
        :key="name"
        class="ev-label-item"
      >
        <span v-if="data.value || (data && typeof data !== 'object')">{{ name }}:</span>
        <p v-if="data.value || (data && typeof data !== 'object')">{{ (data.value ?? data) + ' ' + (data.unit ?? '') }}</p>
      </div>
    </div>

    <!-- <div class="col-md-4">
      <div class="ev-label-item">
        <span>Liczba osi:</span>
        <p>2</p>
      </div>
      <div class="ev-label-item">
        <span>Rozstaw osi:</span>
        <p>2509 mm</p>
      </div>
      <div class="ev-label-item">
        <span>Rozstaw kół (średni):</span>
        <p>1370 mm</p>
      </div>
      <div class="ev-label-item">
        <span>Maksymalny nacisk na oś:</span>
        <p>9 kN</p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "DecoderSection",
  props: {
    decoderData: {
      type: Object,
    },
  },
};
</script>

<style>
</style>