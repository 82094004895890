import { ref } from "vue";
import axios from "axios";

export default function getSampleReport() {
  const response = ref({});

  const req = async () => {
      try {
          response.value = await axios.get("/api/sampleReport");
          return true;
      } catch (error) {
          console.error(error);
      }

      return false;
  };

  return {
    req,
    response,
  };
}