import { ref } from "vue";
import store from "../store/store";

export default function checkResponse(response) {
  const status = ref(response.data.status) || ref(null);
  const message = ref(response.data.message) || ref(null);
  const result = ref({
    message: "",
    stolen: null,
    vinvalidator: {},
    precheckStatus: false,
  });
  const precheckData = ref({});
  const reportData = ref({});

  const setResponse = () => {
    switch (status.value) {
      case "ok":
        result.value.message = "ok";
        result.value.precheckStatus = response.data.result.precheckStatus;
        if (response.data.result.stolenCount > 0) {
          result.value.stolen = true;
        } else {
          result.value.stolen = false;
        }

        if (response.data.result.precheck) {
          precheckData.value = {
            odometers: response.data.result.precheck.odometers,
            damages: response.data.result.precheck.damages,
            events: response.data.result.precheck.events,
            photos: response.data.result.precheck.photos,
          };
        }

        if (response.data.result) {
          reportData.value[response['data']['reportUuid']] = response.data.result;
        }
        break;
      case "error":
        if (
          message.value == "vin_not_validated" &&
          response.data.data.results.vin_correct == null
        ) {
          result.value.message = "incorrect_vin";
        } else if (
          message.value == "vin_not_validated" &&
          response.data.data.results.vin_correct != null
        ) {
          result.value.vinvalidator = response.data.data.results;
          result.value.message = message.value;
        } else if (message.value == 'captcha_failed') {
          result.value.message = "captcha_failed";
        } else if (
          message.value == "captcha_failed" ||
          message.value == "no_data" ||
          message.value == "application_error"
        ) {
          result.value.message = "internal_error";
        } else if (message.value == "not_pass") {
          result.value.message = "not_pass";
        }
        break;

      case "input_error":
        store.commit('updateFillData', response.data.data);
        result.value.message = "input_error";
        break;
      default:
        break;
    }
  };

  const updateVinValidator = (result) => {
    store.commit("updateVinValidator", {
      result,
    });
  };

  const updateMessage = (status) => {
    store.commit("updateMessage", status);
  };

  const updateStolen = (status) => {
    store.commit("updateStolen", status);
  };

  const directionRouter = () => {
    setResponse();
    let routerPush = null;
    //do przeniesienia mutacje
    if (result.value.message == "ok") {
      updateStolen(result.value.stolen);
      store.dispatch("setPrecheckStatus", result.value.precheckStatus);
      if (precheckData.value != {}) {
        store.commit("updatePrecheckData", precheckData.value);
      }

      if (reportData.value != {}) {
        store.commit("updateReportData", reportData.value);
        store.commit("updateReportUuid", reportData.value.reportUuid)

      }

      routerPush = {
        name: "report",
        params: {
          success: true,
          uuid: Object.keys(reportData.value)[0]
        },
      };
    } else if (result.value.message == "vin_not_validated") {
      updateVinValidator(result.value.vinvalidator);

      routerPush = {
        name: "vinValidator",
        params: {
          success: true,
        }
      };
    } else if (
      result.value.message == "internal_error" ||
      result.value.message == "application_error" ||
      result.value.message == "missing_params" ||
      result.value.message == "captcha_failed" ||
      result.value.message == "no_data"
    ) {
      if (result.value.message == 'captcha_failed') {
        store.commit("updateToken", 'TOKEN');
        updateMessage("captcha_failed");
      } else {
        updateMessage("internal_error");
      }

      routerPush = {
        name: "home",
        params: {
          vin: false,
        },
      };
    } else if (result.value.message == "incorrect_vin") {
      routerPush = {
        name: "incorrectVin",
        params: {
          success: false,
        },
      };
    } else if (result.value.message == "not_pass") {
      updateMessage("not_pass");

      routerPush = {
        name: "home",
        params: {
          success: false,
        },
      };
    } else if (result.value.message == "input_error") {
      routerPush = {
        name: "fillData",
        params: {
          success: false,
        },
      };
    }
    // updateMessage(result.value.message);
    return routerPush;
  };

  return {
    directionRouter,
  };
}
