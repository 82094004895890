import Home from "../views/Home";
import FillData from "../views/FillData";
import Loader from "../views/Loader";
import Report from "../views/Report";
import Poc from '../views/static/Poc';
import Rules from '../views/static/Rules';
import Contact from '../views/static/Contact';
import Recaptcha from '../views/Recaptcha';
import VinValidator from '../views/VinValidator';
import IncorrectVin from '../views/IncorrectVin';

export default [
  { path: "/", name: "home", component: Home },
  { path: "/uzupelnij-dane/:vin?/:plate?/:date?", name: "fillData", component: FillData },
  { path: "/sprawdzanie-pojazdu/:vin?/:plate?/:date?", name: "loader", component: Loader },
  { path: "/raport/:uuid?", name: "report", component: Report },
  { path: "/przykladowy-raport/:uuid?", name: "sampleReport", component: Report },
  { path: "/polityka-prywatnosci", name: "poc", component: Poc },
  { path: "/regulamin-serwisu", name: "rules", component: Rules },
  { path: "/kontakt", name: "contact", component: Contact },
  { path: "/recaptcha", name: "recaptcha", component: Recaptcha },
  { path: "/vin-validator", name: "vinValidator", component: VinValidator },
  { path: "/incorrect-vin", name: "incorrectVin", component: IncorrectVin },
  { path: "/index.html", component: Home },
  { path: "/index.htm", component: Home },
  { path: "/index", component: Home },
  { path: "/:catchAll(.*)", component: Home },
];
