<template>
  <Benefit v-for="(data, index) in benefitsData" :key="index" :data="data" />
</template>

<script>
import Benefit from "./Benefit";

export default {
  name: "BenefitsSection",
  components: {
    Benefit,
  },
  setup() {
    const benefitsData = {
      access: {
        icon: "fas fa-universal-access fa-lg text-white fa-fw",
        title: "Dostęp 24/7",
        desc: "Sprawdzaj numer VIN kiedy chcesz. Raporty generowane są całodobowo, 7 dni w tygodni",
      },
      noFees: {
        icon: "fas fa-hand-holding-usd fa-lg text-white fa-fw",
        title: "Bez opłat",
        desc: "Raport w podstawowym zakresie za 0 zł ",
      },
      withoutLogin: {
        icon: "fas fa-sign-in-alt fa-lg text-white fa-fw",
        title: "Bez rejestracji",
        desc: "Generuj raporty bez konieczności rejestracji",
      },
    };

    return {
      benefitsData,
    };
  },
};
</script>

<style>
</style>