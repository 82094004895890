<template>
  <div class="container mt-0 mt-md-2 p-0 p-md-3">
    <!-- Section: Info block -->
    <InfoBlockSection
      v-if="reportData"
      :tiles="reportData.tiles"
      :vehicleInfo="reportData.vehicle_identification"
      :precheckData="reportData.precheckData"
      :additionalData="reportData.additional_data"
    />
    <!-- Section: Info block -->

    <!-- Section: Info vehicle -->
    <VehicleInfoSection
      v-if="reportData"
      :decoderData="reportData.vehicle_identification"
    />
    <!-- Section: Info vehicle -->

    <!-- Section: Info stolen -->
    <StolenSection v-if="reportData" :stolenData="reportData.stolen" />
    <!-- Section: Info stolen -->

    <!-- Section: Timeline -->
    <TimelineSection v-if="reportData" :timelineData="reportData.timeline" />
    <!-- Section: Timeline -->

    <!-- Section: Info odometer -->
    <OdometerSection v-if="reportData" :chartData="reportData.chart_data" />
    <!-- Section: Info odometer -->

    <!-- Section: Info valuation -->
    <ValuationSection v-if="reportData" :valuation="reportData.valuation" />
    <!-- Section: Info valuation -->

    <!-- Section: Info equipment -->
    <EquipmentSection
      v-if="reportData"
      :equipments="reportData.custom_equipment"
    />
    <!-- Section: Info equipment -->
  </div>
</template>

<script>
import InfoBlockSection from "../components/sections/Report/infoBlockSection/InfoBlockSection";
import VehicleInfoSection from "../components/sections/Report/vehicleInfoSection/VehicleInfoSection";
import StolenSection from "../components/sections/Report/stolenSection/StolenSection";
import TimelineSection from "../components/sections/Report/TimelineSection/TimelineSection";
import OdometerSection from "../components/sections/Report/odometerSection/OdometerSection";
import ValuationSection from "../components/sections/Report/valuationSection/ValuationSection";
import EquipmentSection from "../components/sections/Report/equipmentSection/EquipmentSection";

import { useStore } from "vuex";
import {
  computed,
  onMounted,
  // onUnmounted,
  ref,
  watchEffect,
} from "@vue/runtime-core";
import { useRouter } from "vue-router";

import getSampleReport from "@/composables/getSampleReport";

export default {
  name: "Report",
  components: {
    InfoBlockSection,
    VehicleInfoSection,
    StolenSection,
    TimelineSection,
    OdometerSection,
    ValuationSection,
    EquipmentSection,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // const enteredVin = ref(router.currentRoute.value.params.vin);
    const uuid = ref(router.currentRoute.value.params.uuid);
    const reportUuid = ref("");
    const reportData = ref({});

    // const checkedVin = computed(() => {
    //   return store.state.checkedVin;
    // });

    // const checkedPlate = computed(() => {
    //   return store.state.checkedPlate;
    // });

    const data = computed(() => {
      return store.state.reportData;
    });

    watchEffect(() => {
      if (data.value) {
        reportUuid.value = uuid.value;
        reportData.value = data.value[reportUuid.value];
      }
    });

    onMounted(async () => {
      let report = {};

      if (router.currentRoute.value.path.includes("przykladowy-raport")) {
        const { req, response } = getSampleReport();
        const getSample = await req();
        if (getSample) {
          reportUuid.value = response.value.data.report.reportUuid;
          report[uuid.value] = response.value.data.report.result;
          store.commit("updateReportData", report);
        }
      } else if (!(reportData && reportData.value && report)) {
        store.commit("updateReportUuid", router.currentRoute.value.params.uuid || '');
        router.push({
          name: "loader",
          params: {
            uuid: uuid.value,
            report: true,
          },
        });
      }

      const make = reportData.value.vehicle_identification.first_section.Marka;
      const model = reportData.value.vehicle_identification.first_section.Model;
      const year = reportData.value.vehicle_identification.first_section["Rok produkcji"];
      const numberVin = reportData.value.vin;
      document.title = `${numberVin} ${year} ${make} ${model} - easyvin.pl`;
    });

    return {
      reportData,
      reportUuid,
      data,
    };
  },
};
</script>

<style>
</style>